import React, { useState } from "react";
import { makeStyles, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Card, CardContent, Typography } from "@material-ui/core";
import axios from "axios";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  previewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  table: {
    minWidth: 650,
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
  },
  filePreview: {
    width: "1in", // Set width to 1 inch
    height: "1in", // Set height to 1 inch
    border: "1px solid black", // Add border for visualization
    marginBottom: theme.spacing(2), // Add some margin for spacing
  },
}));

const FormPreview = ({ formData, prevStep, nextStep, }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    setLoading(true);
    try {
      // Submit form data
      const response = await axios.post("https://backend.peef.form-seef.com/api/students", formData);
      if (response.status === 201) {
        // Upload image
        // await handleImageUpload(image);
        // Show success message
        Swal.fire({
          icon: "success",
          title: "Form Submitted!",
          html: "Your form has been successfully submitted. <br><br> Follow these steps:<br><br>"
          + "- Take the Print out of the PDF form<br>"
          + "- Get the fomr Signed and Attested by the Principal<br>"
          + "- Post the form along with Affidavit and Required Documents on the Address, mentioned on the backside of the form.",
          showCancelButton: true,
          cancelButtonText: "Close"
        }).then((result) => {
          if (result.isConfirmed) {
            nextStep(); // Go to the next step
          }
        });
      } else {
        // Show error message
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "There was an error submitting the form. Please try again later.",
        });
      }
    } catch (error) {
      // Show error message
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "There was an error submitting the form. Please try again later.",
      });
    }
    setLoading(false);
  };

  
  return (
    <div className={classes.previewContainer}>
      <Paper elevation={5} style={{ padding:'10px'}}>
      <TableContainer>
      <div className="p-3 text-2xl rounded-lg font-bold bg-red-500 ">
                Read the Form Carefully before clicking on SUBMIT button,
                because once the form is submitted it cannot be Edited or
                Corrected.
              </div>
      <div className="p-2 text-center text-2xl rounded-lg font-extrabold underline ">
      Application Form for the PEEF Scholarship
              </div>

              <div className="p-3 flex justify-end">
  {formData.image && <img src={URL.createObjectURL(formData.image)} alt="Uploaded" style={{ width: '144px', height: '192px' }} />}
</div>
        <Table className={classes.table} aria-label="form preview">
         
          <TableBody>
              <div className="w-fit bg-black text-white p-2">
                Section(A) Students Information
                </div> 
<div className="flex">

            
            <TableRow >
  <TableCell component="th" width={180} scope="row">Student Name</TableCell>
  <TableCell component="th" width={5} scope="row">:</TableCell>
  <TableCell align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.name}</TableCell>
  <TableCell component="th" width={"195"}  scope="row">Date of Birth</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.dateOfBirth}</TableCell>
</TableRow>
            
            
</div>

<div className="flex">

            <div>
            <TableRow>
              <TableCell component="th" width={"180"}  scope="row">CNIC / B Form No.</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.cnic}</TableCell>
              <TableCell component="th" width={"180"}  scope="row">Domicile</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.studentDomicile}</TableCell>
            </TableRow>
            </div>
           
</div>

<div className="flex">

            <div>
            <TableRow>
              <TableCell component="th" width={"180"}  scope="row">Father Name</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.fatherName}</TableCell>
            </TableRow>
            </div>
            <div>
            <TableRow>
            <TableCell component="th" width={"195"}  scope="row">Father CNIC</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.fatherCnic}</TableCell>
            </TableRow>
            </div>
</div>

<div className="flex">

            <div>
            <TableRow>
              <TableCell component="th" width={"175"}  scope="row">Father Occupation</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.fatherOccupation}</TableCell>
            </TableRow>
            </div>
            <div>
            <TableRow>
            <TableCell component="th" width={"200"}  scope="row">Father Monthly Income</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.fatherIncome}</TableCell>
            </TableRow>
            </div>
</div>

<div className="flex">

            <div>
            <TableRow>
              <TableCell component="th" width={"180"}  scope="row">Mother Name</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.motherName}</TableCell>
            </TableRow>
            </div>
            <div>
            <TableRow>
            <TableCell component="th" width={"195"}  scope="row">Mother CNIC</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.motherCnic}</TableCell>
            </TableRow>
            </div>
</div>
<div className="flex">

            <div>
            <TableRow>
              <TableCell component="th" width={"165"}  scope="row">House Address</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 600 }}>{formData?.address}</TableCell>
            </TableRow>
            </div>
</div>

<div className="flex">

            <div>
            <TableRow>
              <TableCell component="th" width={"180"}  scope="row">Residential District</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.residenceDistrict}</TableCell>
            </TableRow>
            </div>
            <div>
            <TableRow>
            <TableCell component="th" width={"195"}  scope="row">Email</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.email}</TableCell>
            </TableRow>
            </div>
</div>
<div className="flex">

            <div>
            <TableRow>
              <TableCell component="th" width={"185"}  scope="row">Mobile No. (Parents)</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.parentMobile}</TableCell>
            </TableRow>
            </div>
            <div>
            <TableRow>
            <TableCell component="th" width={"195"}  scope="row">Mobile No. (Student)</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.studentMobile}</TableCell>
            </TableRow>
            </div>
</div>

<div className="w-fit bg-black text-white mt-2 p-2">
                Details of Matriculation / Intermediate Examinations Passed in the Year 2022 (Annual)
                </div> 
<div className="flex">

            <div>
            <TableRow >
  <TableCell component="th" width={180} scope="row">Group (Matric)</TableCell>
  <TableCell component="th" width={5} scope="row">:</TableCell>
  <TableCell align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.matricSubject}</TableCell>
</TableRow>
            </div>
            
</div>

<div className="flex">
           <TableRow>
              <TableCell component="th" width={"180"}  scope="row">Passing Year</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.matricYear}</TableCell>
            </TableRow>
        
            <TableRow>
            <TableCell component="th" width={"195"}  scope="row">Roll No.</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.matricrollNumber}</TableCell>
            </TableRow>

            
</div>

<div className="flex">
           <TableRow>
              <TableCell component="th" width={"260"}  scope="row">Total Marks</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 100 }}>{formData?.matricTotalMarks}</TableCell>
            </TableRow>
        
            <TableRow>
            <TableCell component="th" width={"260"}  scope="row">Marks Obtained</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 100 }}>{formData?.matricmarksObtained}</TableCell>
            </TableRow>

            <TableRow>
            <TableCell component="th" width={"260"}  scope="row">Percentage</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 100 }}>{formData?.matricpercentage}%</TableCell>
            </TableRow>

            
</div>

<div className="flex">

            <div>
            <TableRow >
  <TableCell component="th" width={180} scope="row">Group (Intermediate)</TableCell>
  <TableCell component="th" width={5} scope="row">:</TableCell>
  <TableCell align="left" style={{ fontWeight: 'bold', width: 600 }}>{formData?.intermediateSubject}</TableCell>
</TableRow>
            </div>
            
</div>

<div className="flex">
           <TableRow>
              <TableCell component="th" width={"180"}  scope="row">Passing Year</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.matricYear}</TableCell>
            </TableRow>
        
            <TableRow>
            <TableCell component="th" width={"195"}  scope="row">Roll No.</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.matricrollNumber}</TableCell>
            </TableRow>

            
</div>

<div className="flex">
           <TableRow>
              <TableCell component="th" width={"260"}  scope="row">Total Marks</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 100 }}>{formData?.interTotalMarks}</TableCell>
            </TableRow>
        
            <TableRow>
            <TableCell component="th" width={"260"}  scope="row">Marks Obtained</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 100 }}>{formData?.intermarksObtained}</TableCell>
            </TableRow>

            <TableRow>
            <TableCell component="th" width={"260"}  scope="row">Percentage</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 100 }}>{formData?.interpercentage}%</TableCell>
            </TableRow>

 </div>
  
 <div className=" mt-2 p-2 font-bold text-xl">
                Previous Institute Details from Where last examination has been passed
                </div>
                <div className="flex">
           <TableRow>
              <TableCell component="th" width={"180"}  scope="row">Name of Institute</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 400 }}>{formData?.previnstitutename}</TableCell>
            </TableRow>
        
            <TableRow>
            <TableCell component="th" width={"195"}  scope="row">Institute Type</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.previnstitutetype}</TableCell>
            </TableRow>

            
</div>

<div className="flex">
           <TableRow>
              <TableCell component="th" width={"180"}  scope="row">Address of Institute</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 400 }}>{formData?.previnstituteaddress}</TableCell>
            </TableRow>
        
            <TableRow>
            <TableCell component="th" width={"160"}  scope="row">District</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.previnstitutedistrict}</TableCell>
            </TableRow>

            
</div>


<div className=" text-white bg-black w-fit mt-2 p-2 font-bold text-xl">
                Section (B) : Details of Current Institute (Where Student is studying)
                </div>
                <div className="">
           <TableRow>
              <TableCell component="th" width={"400"}  scope="row">Degree / Course (Student Enrolled in)</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 300 }}>{formData?.institutecourse}</TableCell>
              <TableCell component="th" width={"180"}  scope="row">Roll / Reg. No.</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 120 }}>{formData?.instituterollnumber}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" width={"400"}  scope="row">Duration of Degree / Course (Years)</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 300 }}>{formData?.instituteyear}</TableCell>
              <TableCell component="th" width={"180"}  scope="row">Month</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 120 }}>{formData?.institutemonth}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" width={"250"}  scope="row">Degree / Course Start Date</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 200 }}>{formData?.instituteSdate}</TableCell>
              <TableCell component="th" width={"250"}  scope="row">Expected Completion Date of Degree / Course</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 200 }}>{formData?.instituteEdate}</TableCell>
            </TableRow>
            {/* <TableRow>
            <TableCell component="th" width={"195"}  scope="row">Institute Type</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.previnstitutetype}</TableCell>
            </TableRow> */}

            
</div>
                <div className="flex">
           <TableRow>
              <TableCell component="th" width={"180"}  scope="row">Name of Institute</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 600 }}>{formData?.institute}</TableCell>
            </TableRow>
        
            {/* <TableRow>
            <TableCell component="th" width={"195"}  scope="row">Institute Type</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.previnstitutetype}</TableCell>
            </TableRow> */}

            
</div>

<div className="">
           <TableRow>
              <TableCell component="th" width={"180"}  scope="row">Address of Institute</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 400 }}>{formData?.instituteaddress}</TableCell>
              <TableCell component="th" width={"160"}  scope="row">District</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.currentinstitutedistrict}</TableCell>
            </TableRow>
        
            <TableRow>
              <TableCell component="th" width={"180"}  scope="row">Name of Principal / Head of Institute</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 400 }}>{formData?.institutehead}</TableCell>
              {/* <TableCell component="th" width={"160"}  scope="row">District</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 220 }}>{formData?.currentinstitutedistrict}</TableCell> */}
            </TableRow>

            <TableRow>
              <TableCell component="th" width={"480"}  scope="row">Contact No. of Principal / Head of Dept. of the Institute (PTCL)</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 200 }}>{formData?.instituteptcl}</TableCell>
              <TableCell component="th" width={"100"}  scope="row">Mobile No.</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 280 }}>{formData?.institutemobile}</TableCell>
            </TableRow>
            
            <TableRow>
              <TableCell component="th" width={"280"}  scope="row">Bank Account Title of Institute</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 280 }}>{formData?.institutebank}</TableCell>
              <TableCell component="th" width={"100"}  scope="row">NTN No.</TableCell>
              <TableCell component="th"  width={"5"} scope="row">:</TableCell>
              <TableCell  align="left" style={{ fontWeight: 'bold', width: 280 }}>{formData?.institutentn}</TableCell>
            </TableRow>
</div>
            {/* Add more rows for other fields */}
          </TableBody>
        </Table>
      </TableContainer>
      </Paper>
      <div
        style={{
          marginTop: "3rem",
          display: "flex",
          gap: "0.5rem",
          paddingBottom: "1rem",
          justifyContent: "space-between",
        }}
      >
        <Button variant="contained" color="primary" onClick={prevStep}>
          Edit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleNext}
          disabled={loading} // Disable button when loading
        >
          {loading ? "Please wait..." : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default FormPreview;
