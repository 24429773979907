import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  InputLabel,
  MenuItem
} from "@material-ui/core";

// Districts array
const districts = [
  'Badin', 'Dadu', 'Ghotki', 'Hyderabad', 'Jacobabad', 'Jamshoro', 'Karachi (Central)', 'Karachi (East)',
  'Karachi (Keamari)', 'Karachi (Korangi)', 'Karachi (Malir)', 'Karachi (South)', 'Karachi (West)', 
  'Kashmore @ Kandhkot', 'Khairpur', 'Larkana', 'Matiari', 'MirpurKhas', 'Naushahro Feroze', 
  'Qambar Shahdadkot', 'Sanghar', 'Shaheed BenazirAbad', 'Shikarpur', 'Sujawal', 'Sukkur', 
  'Tando Allahyar', 'Tando Muhammad Khan', 'Tharparkar', 'Thatta', 'Umerkot'
];

// Array of required fields
const requiredFields = [
  "matricSubject","matricYear", "matricrollNumber", "matricTotalMarks", "matricmarksObtained", "matricpercentage",
  "previnstitutename", "previnstitutetype", "previnstituteaddress", "previnstitutedistrict"
];

const PassingDetails = ({ formData, setFormData, nextStep, prevStep }) => {
  const [errors, setErrors] = useState({matricSubject: false,});
  const [helperText, setHelperText] = useState({});
  const [selectedMatricSubject, setSelectedMatricSubject] = useState("");
  const [selectedIntermediateSubject, setSelectedIntermediateSubject] =
    useState("");

    const validateForm = () => {
      const newErrors = {};
      const newHelperText = {};
    
      // Check for required fields
      requiredFields.forEach((field) => {
        if (!formData[field]) {
          newErrors[field] = true;
          newHelperText[field] = "This field is required.";
        } else {
          newErrors[field] = false;
          newHelperText[field] = "";
        }
      });
    
      // Check if at least one matriculation checkbox is checked
      if (!(formData.matricSubject === "Arts" || formData.matricSubject === "Science")) {
        newErrors.matricSubject = true;
        newHelperText.matricSubject = "At least one option must be selected.";
      } else {
        newErrors.matricSubject = false;
        newHelperText.matricSubject = "";
      }
    
      setErrors(newErrors);
      setHelperText(newHelperText);
    
      // Check if there are no errors
      return Object.values(newErrors).every((error) => !error);
    };

// Handle change in matric subject
const handleMatricSubjectChange = (subject) => {
  setSelectedMatricSubject(subject);
  setFormData({ ...formData, matricSubject: subject });
};



const handleChange = (e) => {
  const { name, value } = e.target;

  // Update form data
  setFormData({ ...formData, [name]: value });

  // Update helper text only if the field is empty
  if (value.trim() === '') {
    setHelperText({ ...helperText, [name]: 'This field is required.' });
  } else {
    setHelperText({ ...helperText, [name]: '' });
  }
};

// Function to compute percentage
const computematricPercentage = () => {
  const { matricTotalMarks, matricmarksObtained } = formData;
  if (matricTotalMarks && matricmarksObtained) {
    const percentage = (parseFloat(matricmarksObtained) / parseFloat(matricTotalMarks)) * 100;
    setFormData({ ...formData, matricpercentage: percentage.toFixed(2) });
  }
};

const computeinterPercentage = () => {
  const { interTotalMarks, intermarksObtained } = formData;
  if (interTotalMarks && intermarksObtained) {
    const percentage = (parseFloat(intermarksObtained) / parseFloat(interTotalMarks)) * 100;
    setFormData({ ...formData, interpercentage: percentage.toFixed(2) });
  }
};

const handleIntegerInput = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  const isValidInput = /^\d$/.test(keyValue); // Change the regex pattern to allow only single digits
  if (!isValidInput) {
    event.preventDefault();
  }
};

const handleYearChange = (e) => {
  const { name, value } = e.target;
  // Limit the input to only two digits
  const inputValue = value.replace(/\D/g, '').slice(0, 4); // Filter out non-digit characters and keep only first two digits
  setFormData({ ...formData, [name]: inputValue });
  setErrors({ ...errors, [name]: false });
  setHelperText({ ...helperText, [name]: "" });
};

  const handleIntermediateSubjectChange = (subject) => {
    setSelectedIntermediateSubject(subject);
    setFormData({ ...formData, intermediateSubject: subject });
  };

  const handleNext = () => {
    if (validateForm()) {
      console.log(formData); // Display the filled data in the console
      nextStep();
    }
  };

  const handleBack = () => {
    prevStep();
  };

  const containerStyle = {
    padding: "1rem", // Add your desired padding value here
  };

  const typographyStyle = {
    // fontWeight: "bold",
    whiteSpace: "pre-wrap",
    marginRight: "0.5rem",
    padding: "0.5rem", // Add your desired padding value here
    // width: "200px", // Set the desired width
    lineHeight:"1.5em"
  };

  const textFieldStyle = {
    flex: "1",
  };

 
 

  return (
    <>
      <Typography
        variant="h5"
        style={{ fontWeight: "bold", textAlign: "center", marginTop: "1rem" }}
      >
        Details of Matriculation / Intermediate Examinations Passed in the Year
        2023 (Annual)
      </Typography>

      <Container style={containerStyle}>
        {/* Matriculation Group */}
        <Typography
          variant="h5"
          style={{ marginTop: "1rem", fontWeight: "bold", textAlign: "center" }}
        >
          Matriculation
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "0.8rem",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            style={{ fontWeight: "bold", marginRight: "1rem" }}
          >
            Group:
          </Typography>
          {/* Matriculation Arts */}
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedMatricSubject === "Arts"}
                onChange={() => handleMatricSubjectChange("Arts")}
                color="primary"
              />
            }
            labelPlacement="start"
            label={<Typography variant="h6">Arts / General Science</Typography>}
            onClick={(event) => event.stopPropagation()}
            style={{ marginRight: "1rem" }}
            
          />
          {/* Matriculation Science */}
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedMatricSubject === "Science"}
                onChange={() => handleMatricSubjectChange("Science")}
                color="primary"
              />
            }
            labelPlacement="start"
            label={<Typography variant="h6">Science</Typography>}
            onClick={(event) => event.stopPropagation()}
          />
          
        </div>
        {errors.matricSubject && (
  <Typography variant="body2" style={{ color: "red" }}>
    At least one Matric Subject must be selected.
  </Typography>
)}
        {/* Educational Year */}
         <div style={{ marginBottom: "1rem" }}>
    <InputLabel style={{ ...typographyStyle }}>Passing Year</InputLabel>
    <TextField
      name="matricYear"
      value={formData.matricYear}
      onChange={handleChange}
      onKeyPress={handleIntegerInput} // Add onKeyPress event handler
      fullWidth
      variant="outlined"
      inputProps={{ maxLength: 4, style: textFieldStyle }} // Limit input to 4 characters
      error={errors.matricYear}
      helperText={errors.matricYear}

    />
  </div>

        {/* Roll Number */}
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={{ ...typographyStyle }}>Roll Number</InputLabel>
          <TextField
            name="matricrollNumber"
            value={formData.matricrollNumber}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            inputProps={{ style: textFieldStyle }}
            error={errors.matricrollNumber}
            helperText={helperText.matricrollNumber}
          />
          
        </div>


        <div style={{ marginBottom: "1rem" }}>
  <InputLabel style={{ ...typographyStyle }}>Total Marks</InputLabel>
  <TextField
    name="matricTotalMarks"
    value={formData.matricTotalMarks}
    onChange={handleYearChange}
    onBlur={computematricPercentage}
    fullWidth
    variant="outlined"
    // inputProps={{ maxLength: 4, style: textFieldStyle }}
    error={errors.matricTotalMarks}
    helperText={helperText.matricTotalMarks}
/>

          
        </div>
        {/* Marks Obtained */}
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={{ ...typographyStyle }}>Marks Obtained</InputLabel>
          <TextField
            name="matricmarksObtained"
            value={formData.matricmarksObtained}
            onChange={handleYearChange}
            onBlur={computematricPercentage} // Compute percentage on blur
            fullWidth
            variant="outlined"
            // inputProps={{ maxLength: 4, style: textFieldStyle }} // Limit input to 4 characters
            error={errors.matricmarksObtained}
            helperText={helperText.matricmarksObtained}
          />
        </div>

        <div style={{ marginBottom: '1rem' }}>
        <InputLabel>Percentage</InputLabel>
        <TextField
          name="matricpercentage"
          value={formData.matricpercentage}
          onChange={handleChange}
          fullWidth
          disabled
          variant="outlined"
          // error={errors.matricpercentage}
          // helperText={helperText.matricpercentage}
        />
      </div>

        {/* Intermediate Group */}
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textAlign: "center", marginTop: "2rem" }}
        >
          Intermediate
        </Typography>
        <div
          style={{
            display: "flex",
            // alignItems: "center",
            marginTop: "0.8rem",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
          className="academicsGroupAndCheckBoxParent"
        >
          <div
            style={{
              fontWeight: "bold",
              marginRight: "1rem",
              flex: 0.2,
              marginTop: "1rem",
              fontSize: "22px",
            }}
            className="academicsGroup"
          >
            Group:
          </div>
          {/* Intermediate Commerce */}
          <div style={{ flex: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedIntermediateSubject === "Commerce"}
                  onChange={() => handleIntermediateSubjectChange("Commerce")}
                  color="primary"
                />
              }
              labelPlacement="start"
              label={<Typography variant="h6">Commerce</Typography>}
              style={{ ...typographyStyle, width: "auto" }}
            />
            {/* Intermediate Pre-Engineering */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedIntermediateSubject === "Pre-Engineering"}
                  onChange={() =>
                    handleIntermediateSubjectChange("Pre-Engineering")
                  }
                  color="primary"
                />
              }
              labelPlacement="start"
              label={<Typography variant="h6">Pre-Engineering</Typography>}
              style={{ ...typographyStyle, width: "auto" }}
            />
            {/* Intermediate Pre-Medical */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedIntermediateSubject === "Pre-Medical"}
                  onChange={() =>
                    handleIntermediateSubjectChange("Pre-Medical")
                  }
                  color="primary"
                />
              }
              labelPlacement="start"
              label={<Typography variant="h6">Pre-Medical</Typography>}
              style={{ ...typographyStyle, width: "auto" }}
            />
            {/* </div> */}
            {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        > */}
            {/* Intermediate Humanities/Arts */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedIntermediateSubject === "Humanities"}
                  onChange={() => handleIntermediateSubjectChange("Humanities")}
                  color="primary"
                />
              }
              labelPlacement="start"
              label={<Typography variant="h6">(Humanities)/Arts</Typography>}
              style={{ ...typographyStyle, width: "auto" }}
            />
            {/* Intermediate ICS/General Science */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedIntermediateSubject === "General Science"}
                  onChange={() =>
                    handleIntermediateSubjectChange("General Science")
                  }
                  color="primary"
                />
              }
              labelPlacement="start"
              label={<Typography variant="h6">ICS/General Science</Typography>}
              style={{ ...typographyStyle, width: "auto" }}
            />
          </div>
        </div>

        {/* Educational Year Intermediate */}
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={{ ...typographyStyle }}>Passing Year</InputLabel>
          <TextField
            name="interYear"
            value={formData.interYear}
            onChange={handleYearChange}
            fullWidth
            variant="outlined"
            inputProps={{ style: textFieldStyle }}
            
          />
        </div>

        {/* Roll Number Intermediate */}
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={{ ...typographyStyle }}>Roll Number</InputLabel>
          <TextField
            name="interRollNumber"
            value={formData.interRollNumber}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            inputProps={{ style: textFieldStyle }}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={{ ...typographyStyle }}>Total Marks</InputLabel>
          <TextField
            name="interTotalMarks"
            value={formData.interTotalMarks}
            onChange={handleYearChange}
            // onKeyPress={handleIntegerInput} // Add onKeyPress event handler
            fullWidth
            variant="outlined"
            inputProps={{ style: textFieldStyle }}
          />
          
        </div>
        {/* Marks Obtained */}
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={{ ...typographyStyle }}>Marks Obtained</InputLabel>
          <TextField
            name="intermarksObtained"
            value={formData.intermarksObtained}
            onChange={handleYearChange}
            // onKeyPress={handleIntegerInput} // Add onKeyPress event handler
            onBlur={computeinterPercentage} // Compute percentage on blur
            fullWidth
            variant="outlined"
            inputProps={{ style: textFieldStyle }}
          />
        </div>

        <div style={{ marginBottom: '1rem' }}>
        <InputLabel>Percentage</InputLabel>
        <TextField
          name="interpercentage"
          value={formData.interpercentage}
          onChange={handleChange}
          fullWidth
          disabled
          variant="outlined"
        />
      </div>

        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textAlign: "center", marginTop: "1rem" }}
        >
          Details of the previous educational institution from where the
          examination was passed.
        </Typography>
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={{ ...typographyStyle }}>
            Complete Name of the Educational Institution
          </InputLabel>
          <TextField
            name="previnstitutename"
            value={formData.previnstitutename}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            inputProps={{ style: textFieldStyle }}
            error={errors.previnstitutename}
            helperText={helperText.previnstitutename}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
        <InputLabel style={typographyStyle}>Institute Type</InputLabel>
        <TextField
          name="previnstitutetype"
          value={formData.previnstitutetype}
          onChange={handleChange}
          select // Add the select prop to convert it into a dropdown
          fullWidth
          variant="outlined"
          inputProps={{ style: textFieldStyle }}
          error={errors.previnstitutetype}
            helperText={helperText.previnstitutetype}
        
        >
          {/* Add MenuItem elements to populate the options in the dropdown */}
          <MenuItem value="Government">Government</MenuItem>
          <MenuItem value="Private">Private</MenuItem>
          
          {/* Add more MenuItem elements for other district options */}
        </TextField>
</div>
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel
            style={{ ...typographyStyle }}
            className="inputLabelCompleteAddress"
          >
            Complete Address of the Educational Institution
          </InputLabel>
          <TextField
            name="previnstituteaddress"
            value={formData.previnstituteaddress}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            inputProps={{ style: textFieldStyle }}
            error={errors.previnstituteaddress}
            helperText={helperText.previnstituteaddress}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
        <InputLabel style={typographyStyle}>District</InputLabel>
        <TextField
          name="previnstitutedistrict"
          value={formData.previnstitutedistrict}
          onChange={handleChange}
          select // Add the select prop to convert it into a dropdown
          fullWidth
          variant="outlined"
          inputProps={{ style: textFieldStyle }}
          error={errors.previnstitutedistrict}
          helperText={helperText.previnstitutedistrict}
         
        >


          {/* Add MenuItem elements to populate the options in the dropdown */}
           {/* Add an empty disabled MenuItem for the top label */}
     <MenuItem disabled value="">
      Please select district
    </MenuItem>
    {/* Add MenuItem elements to populate the options in the dropdown */}
   {/* Map over the districts array to generate MenuItem elements */}
   {districts.map((district) => (
              <MenuItem key={district} value={district}>
                {district}
              </MenuItem>
            ))}
          
        </TextField>
</div>
        <div
          style={{
            marginTop: "3rem",
            display: "flex",
            gap: "0.5rem",
            paddingBottom: "1rem",
            justifyContent: "space-between",
          }}
        >
          <Button variant="contained" color="secondary" onClick={handleBack}>
            Back
          </Button>
          <Button
  variant="contained"
  color="primary"
  onClick={handleNext}
  // fullWidth
  // style={{ marginBottom: "2rem", marginTop: "1rem" }}
  // Disable the button if any required fields are empty or loading state is true
  disabled={
    !formData.matricSubject || // Add more required fields as needed
    !formData.matricYear ||
    !formData.matricrollNumber ||
    !formData.matricTotalMarks ||
    !formData.matricmarksObtained ||
    !formData.matricpercentage ||
    // !formData.intermediateSubject ||
    // !formData.interYear ||
    // !formData.interRollNumber ||
    // !formData.interTotalMarks ||
    // !formData.intermarksObtained ||
    // !formData.interpercentage ||
    !formData.previnstitutename ||
    !formData.previnstitutetype ||
    !formData.previnstituteaddress ||
    !formData.previnstitutedistrict
  }
>
  Next
  </Button>
        </div>
      </Container>
    </>
  );
};

export default PassingDetails;
