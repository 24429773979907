import React from "react";

const Header = () => {
  return (
    <header className="flex justify-between text-center mx-auto">
      {/* <div className="flex items-center">
        <img
          className="w-20"
          src="/logo.png"
          alt="Logo"
        />
      </div> */}
      <div className="bg-black text-white p-3 font-bold">
        <h2 className="md:text-xl lg:text-xl">
        حکومت پنجاب کا ہونہار و مستحق طلبا و طالبات کی اعلیٰ تعلیم کے لئے سکالرشپ پروگرام

          <p>THE PUNJAB EDUCATIONAL ENDOWMENT FUND (PEEF)
</p>
          
          {/* <p>REGISTERED VIDE TRUST DEED NO. 625/2020</p> */}
        </h2>
      </div>
    </header>
  );
};

export default Header;
