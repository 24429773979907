import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

const Hostel_Scholarship = ({ formData, setFormData, nextStep, prevStep }) => {
  // State variables for checkboxes and errors
  const [isStayingInHostel, setIsStayingInHostel] = useState(null);
  const [isReceivingScholarship, setIsReceivingScholarship] = useState(null);
  const [selectedSpecialQuota, setSelectedSpecialQuota] = useState(null);
  const [errors, setErrors] = useState({});

 

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Validate fields and set errors if necessary
    if (isStayingInHostel === null) {
      newErrors.isStayingInHostel = true;
      isValid = false;
    }

    if (isReceivingScholarship === null) {
      newErrors.isReceivingScholarship = true;
      isValid = false;
    }

    if (selectedSpecialQuota === null) {
      newErrors.selectedSpecialQuota = true;
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const containerStyle = {
    padding: "1rem",
  };

  const handleHostelChange = (value) => {
    const hostelValue = value ? "Yes" : "No";
    setIsStayingInHostel(value);
    setFormData({ ...formData, isStayingInHostel: hostelValue });
  };

  const handleScholarshipChange = (value) => {
    const scholarshipValue = value ? "Yes" : "No";
    setIsReceivingScholarship(value);
    setFormData({ ...formData, isReceivingScholarship: scholarshipValue });
  };

  const handleSpecialQuotaChange = (value) => {
    setSelectedSpecialQuota(value);
    setFormData({ ...formData, selectedSpecialQuota: value });
  };

  const typographyStyle = {
    lineHeight: "1.5em",
    fontSize: "18px",
    textAlign: "justify"
  };

  const handleNext = () => {
    // Validate the form before proceeding to the next step
    if (validateForm()) {
      console.log(formData); // Display the filled data in the console
      nextStep();
    }
  };
  // Function to check if any required field is empty
const areRequiredFieldsEmpty = () => {
  return (
    isStayingInHostel === null ||
    isReceivingScholarship === null ||
    selectedSpecialQuota === null
  );
};

  return (
    <>
      <Typography
        variant="h5"
        style={{ ...typographyStyle, fontSize: "25px", fontWeight: "bold" }}
      >
        Details of the hostel provided by the current educational institution.
      </Typography>

      <Container style={containerStyle}>
        <Typography
          variant="h6"
          style={{ ...typographyStyle }}
        >
          Are you currently staying in the hostel provided by your educational
          institution?
        </Typography>
        <div style={{ ...typographyStyle, display: "flex", alignItems: "center", justifyContent: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isStayingInHostel}
                onChange={(e) => handleHostelChange(e.target.checked)}
                color="primary"
              />
            }
            label="Yes"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isStayingInHostel == null ? null : !isStayingInHostel}
                onChange={(e) => handleHostelChange(!e.target.checked)}
                color="primary"
              />
            }
            label="No"
          />
        </div>
        {errors.isStayingInHostel && (
          <Typography variant="body2" style={{ color: "red" }}>
            This field is required.
          </Typography>
        )}
<div style={{ display: "flex" }}>
           <Typography
             variant="h6"
             style={{
               fontWeight: "bold",
               marginTop: "0.5rem",
               textAlign: "justify",
             }}
           >
             Note:
           </Typography>
           <Typography
             variant="h6"
             style={{ marginTop: "0.5rem", textAlign: "justify" }}
           >
             If Yes, then you have to obtain verification from the Hostel
             Superintendent / Warden?
           </Typography>
         </div>


        
        <Typography
          variant="h5"
          style={{ ...typographyStyle, fontWeight: "bold", marginTop: "2rem" }}
        >
          Details of Scholarship.
        </Typography>
        <Typography
          variant="h6"
          style={{ ...typographyStyle }}
        >
          Are you currently receiving any scholarship in the current academic
          year?
        </Typography>
        <div style={{ ...typographyStyle, display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Checkbox
            checked={isReceivingScholarship}
            onChange={(e) => handleScholarshipChange(e.target.checked)}
            color="primary"
          />
          <Typography variant="h6">Yes</Typography>
          <Checkbox
            checked={isReceivingScholarship == null ? null : !isReceivingScholarship}
            onChange={(e) => handleScholarshipChange(!e.target.checked)}
            color="primary"
          />
          <Typography variant="h6">No</Typography>
        </div>
        {errors.isReceivingScholarship && (
          <Typography variant="body2" style={{ color: "red" }}>
            This field is required.
          </Typography>
        )}

<div style={{ display: "flex" }}>
           <Typography
             variant="h6"
             style={{
               fontWeight: "bold",
               marginTop: "0.5rem",
               textAlign: "justify",
             }}
           >
             Note:
           </Typography>
           <Typography
             variant="h6"
             style={{ marginTop: "0.5rem", textAlign: "justify" }}
           >
             As per Policy of PEEF student is not allowed to avail more than one
             scholarship. If scholarship is awarded, student have to withdraw the
             already availing Scholarship.
           </Typography>
         </div>

        <Typography
          variant="h5"
          style={{ ...typographyStyle, fontWeight: "bold", marginTop: "2rem" }}
        >
          Special Quota Scholarship.
        </Typography>
        <Typography
          variant="h6"
          style={{ ...typographyStyle, marginTop: "1rem" }}
        >
          If you belong to any of the following categories, you are also
          eligible for special quota scholarship. Please provide the details of
          the category you qualify for and attach relevant qualifications and
          documents/certificates.
        </Typography>
        <div style={{ ...typographyStyle, display: "flex", flexDirection: "column", marginTop: "0.8rem" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedSpecialQuota === "NotApplicable"}
                onChange={() => handleSpecialQuotaChange("NotApplicable")}
                color="primary"
              />
            }
            label="Not Applicable"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedSpecialQuota === "OrphanStudent"}
                onChange={() => handleSpecialQuotaChange("OrphanStudent")}
                color="primary"
              />
            }
            label="Orphan Student"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedSpecialQuota === "ChildrenOfGovtServant"}
                onChange={() => handleSpecialQuotaChange("ChildrenOfGovtServant")}
                color="primary"
              />
            }
            label="Children of Grade 1-4 Govt. Servant"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedSpecialQuota === "StudentsBelongingToMinorities"}
                onChange={() => handleSpecialQuotaChange("StudentsBelongingToMinorities")}
                color="primary"
              />
            }
            label="Students Belonging to Minorities"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedSpecialQuota === "DisabledStudents"}
                onChange={() => handleSpecialQuotaChange("DisabledStudents")}
                color="primary"
              />
            }
            label="Disabled Students"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedSpecialQuota === "ChildrenOfMartyredCivilians"}
                onChange={() => handleSpecialQuotaChange("ChildrenOfMartyredCivilians")}
                color="primary"
              />
            }
            label="Children of the civilians who were martyred in terrorist attacks."
          />
        </div>
        {errors.selectedSpecialQuota && (
          <Typography variant="body2" style={{ color: "red" }}>
            This field is required.
          </Typography>
        )}
        <div style={{ marginTop: "3rem", display: "flex", gap: "0.5rem", paddingBottom: "1rem", justifyContent: "space-between" }}>
          <Button variant="contained" color="secondary" onClick={prevStep}>
            Back
          </Button>
          <Button
  variant="contained"
  color="primary"
  onClick={handleNext}
  disabled={areRequiredFieldsEmpty()} // Disable the button if any required field is empty
>
  Preview
</Button>
        </div>
      </Container>
    </>
  );
};

export default Hostel_Scholarship;
