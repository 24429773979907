

import React, { useState } from "react";
import { Container, makeStyles, useTheme, Step, StepLabel, Stepper, Button } from "@material-ui/core";
import Swal from "sweetalert2";
import Personal from "./SectionA_Information";
import Academic from "./Academic";
import Hostel_Scholarship from "./Hostel_Scholarship";
import CurrentInstitute from "./CurrentInstitute";
import Header from "./components/Header/Header";
import FormPreview from "./FormPreview";
import PDF from "./PDF";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%", // Set width to 100% for responsiveness
    padding: theme.spacing(3),
    backgroundColor: "",
    height: "80%",
    justifyContent: "center",
    boxShadow: "0px 10px 12px rgba(0, 0, 0, 0.8)",
    [theme.breakpoints.down("md")]: {
      width: "95%", // Adjust width for medium screens
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%", // Adjust width for small screens
    },
  },
  stepBar: {
    width: "100%", // Set width to 100% for responsiveness
    maxWidth: "100%", // Adjust the maximum width for responsiveness
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      display: "block",
      
    },
  },
  stepLabel: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
      padding: theme.spacing(1),
    },
  },
}));

const MultiStepForm = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [image, setImage] = useState(null);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };


 

  // const handleNext = async () => {
  //   setLoading(true);
  //   try {
  //     // Submit form data
  //     const response = await axios.post("http://localhost:8000/api/students", formData);
  //     if (response.status === 201) {
  //       // Upload image
  //       // await handleImageUpload(image);
  //       // Show success message
  //       Swal.fire({
  //         icon: "success",
  //         title: "Form Submitted!",
  //         text: "Your form has been successfully submitted.",
  //         showCancelButton: true,
  //         cancelButtonText: "Close",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           nextStep(); // Go to the next step
  //         }
  //       });
  //     } else {
  //       // Show error message
  //       Swal.fire({
  //         icon: "error",
  //         title: "Error!",
  //         text: "There was an error submitting the form. Please try again later.",
  //       });
  //     }
  //   } catch (error) {
  //     // Show error message
  //     console.error("Error submitting form:", error);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error!",
  //       text: "There was an error submitting the form. Please try again later.",
  //     });
  //   }
  //   setLoading(false);
  // };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Personal
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            setImage={setImage} // Pass setImage function down to Personal component
          />
        );
      case 2:
        return (
          <Academic
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 3:
        return (
          <CurrentInstitute
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 4:
        return (
          <Hostel_Scholarship
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        );
      case 5:
        return (
          <FormPreview
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            prevStep={prevStep}
            image={image}
          />
        );

        case 6:
          return (
            <PDF
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              prevStep={prevStep}
              image={image}
            />
          );
    }
  };

  return (
    <Container className={classes.container}>
    <Header />
    <Stepper activeStep={step - 1} className={classes.stepBar}>
      <Step>
        <StepLabel classes={{ label: classes.stepLabel }}>
          Section A: <br />
          (Student Information)
        </StepLabel>
      </Step>
      <Step>
        <StepLabel classes={{ label: classes.stepLabel } }>Details of Matriculation / Intermediate</StepLabel>
      </Step>
      <Step>
        <StepLabel classes={{ label: classes.stepLabel }}>Section (B) Details of Current Educational Institution</StepLabel>
      </Step>
      <Step>
        <StepLabel classes={{ label: classes.stepLabel }}>
          Hostel & Scholarship Details
        </StepLabel>
      </Step>
      <Step>
        <StepLabel classes={{ label: classes.stepLabel }}>Preview</StepLabel>
      </Step>
      <Step>
        <StepLabel classes={{ label: classes.stepLabel }}>Download Form</StepLabel>
      </Step>
    </Stepper>
    {renderStep()}
  </Container>
  );
};

export default MultiStepForm;
