import React from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Form from "./MultiStepForm";
import Login from "./login/Login";
import Dashboard from "./adminpanel/index"
// import Universities from "./adminpanel/universities"
// import Discipline from "./adminpanel/discipline"
// import Domicile from "./adminpanel/domicile"
// import Admissionyear from "./adminpanel/admissionyear"
// import AdminPanel from "./adminpanel"
// import Pdf from './form/Pdf';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        {/* <Route path="/pdf" element={<Pdf />} /> */}
        <Route path="/form" element={<Form />} />
        <Route path="/login" element={<Login />} />
        <Route path="/adminpanel/" element={<Dashboard />} />
        {/* <Route path="/adminpanel/universities/" element={<Universities />} /> */}
        {/* <Route path="/adminpanel/discipline/" element={<Discipline />} /> */}
        {/* <Route path="/adminpanel/domicile/" element={<Domicile />} /> */}
        {/* <Route path="/adminpanel/admissionyear/" element={<Admissionyear />} /> */}
        {/* <Route path="/adminpanel" element={<AdminPanel />} /> */}
        {/* <Route path="" element={<AdminPanel />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
