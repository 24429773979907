import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
  InputLabel,
  MenuItem,
} from "@material-ui/core";

// Districts array
const districts = [
  'Badin', 'Dadu', 'Ghotki', 'Hyderabad', 'Jacobabad', 'Jamshoro', 'Karachi (Central)', 'Karachi (East)',
  'Karachi (Keamari)', 'Karachi (Korangi)', 'Karachi (Malir)', 'Karachi (South)', 'Karachi (West)', 
  'Kashmore @ Kandhkot', 'Khairpur', 'Larkana', 'Matiari', 'MirpurKhas', 'Naushahro Feroze', 
  'Qambar Shahdadkot', 'Sanghar', 'Shaheed BenazirAbad', 'Shikarpur', 'Sujawal', 'Sukkur', 
  'Tando Allahyar', 'Tando Muhammad Khan', 'Tharparkar', 'Thatta', 'Umerkot'
];

// Array of required fields
const requiredFields = [
  "currentstudylevel","institute", "instituteaddress", "currentinstitutedistrict", "institutehead", "instituteptcl",
  "institutemobile", "institutecourse", "institutebank", "institutentn", "instituteyear", "institutemonth", "instituterollnumber", "instituteSdate" , "instituteEdate"
];

const CurrentInstitute = ({ formData, setFormData, nextStep, prevStep }) => {
  const [errors, setErrors] = useState({});
  const [helperText, setHelperText] = useState({});

 // Function to handle input change
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
  
  // Only clear the error if the field is filled
  if (value) {
    setErrors({ ...errors, [name]: false });
    setHelperText({ ...helperText, [name]: "" });
  }
};



  // Function to validate form
  const validateForm = () => {
    const newErrors = {};
    const newHelperText = {};
  
    // Check for required fields
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = true;
        newHelperText[field] = "This field is required.";
      } else {
        newErrors[field] = false;
        newHelperText[field] = "";
      }
    });
  
    setErrors(newErrors);
    setHelperText(newHelperText);
  
    // Check if there are no errors
    return Object.values(newErrors).every((error) => !error);
  };
  
  // Function to handle input change for year field
const handleYearChange = (e) => {
  const { name, value } = e.target;
  // Limit the input to only two digits
  const inputValue = value.replace(/\D/g, '').slice(0, 2); // Filter out non-digit characters and keep only first two digits
  setFormData({ ...formData, [name]: inputValue });
  setErrors({ ...errors, [name]: false });
  setHelperText({ ...helperText, [name]: "" });
};

  // Function to handle next button click
  const handleNext = () => {
    if (validateForm()) {
      console.log(formData); // Display the filled data in the console
      nextStep();
    }
  };
// Function to check if any required field is empty
const areRequiredFieldsEmpty = () => {
  return requiredFields.some(field => !formData[field]);
};

  // Function to handle back button click
  const handleBack = () => {
    prevStep();
  };

  // Styles
  const typographyStyle = {
    whiteSpace: "pre-wrap",
    marginRight: "0.5rem",
    padding: "0.5rem",
    width: "auto",
    lineHeight: "1.5em"
  };

  const textFieldStyle = {
    flex: "1",
  };

  return (
    <>
      <Typography
        variant="h6"
        style={{ ...typographyStyle, textAlign: "center", marginTop: "1rem" }}
      >
        Section (B)
        <br />
        Details of Current Educational Institution (Intermediate Details)
      </Typography>
      <Container style={{ padding: "1rem" }}>
        
        {/* Study Level Dropdown */}
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={typographyStyle}>Study Level</InputLabel>
          <TextField
            name="currentstudylevel"
            value={formData.currentstudylevel}
            onChange={handleChange}
            select
            fullWidth
            variant="outlined"
            inputProps={{ style: textFieldStyle }}
            error={errors.currentstudylevel}
            helperText={helperText.currentstudylevel}
          >
            <MenuItem value="Intermediate">Intermediate</MenuItem>
            <MenuItem value="ADA">ADA</MenuItem>
            <MenuItem value="ADC">ADC</MenuItem>
            <MenuItem value="ADS">ADS</MenuItem>
          </TextField>
        </div>
        
        {/* Full Name of the Educational Institution */}
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={typographyStyle}>
            Full Name of the Educational Institution:
          </InputLabel>
          <TextField
            name="institute"
            value={formData.institute}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={errors.institute}
            helperText={helperText.institute}
          />
        </div>

        {/* Complete Address of the Educational Institution */}
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={typographyStyle}>
            Complete Address of the Educational Institution (for correspondence):
          </InputLabel>
          <TextField
            name="instituteaddress"
            value={formData.instituteaddress}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={errors.instituteaddress}
            helperText={helperText.instituteaddress}
          />
        </div>

        {/* District Dropdown */}
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={typographyStyle}>District</InputLabel>
          <TextField
            name="currentinstitutedistrict"
            value={formData.currentinstitutedistrict}
            onChange={handleChange}
            select
            fullWidth
            variant="outlined"
            inputProps={{ style: textFieldStyle }}
            error={errors.currentinstitutedistrict}
            helperText={helperText.currentinstitutedistrict}
          >
            {/* Add an empty disabled MenuItem for the top label */}
            <MenuItem disabled value="">
              Please select district
            </MenuItem>
            {/* Map over the districts array to generate MenuItem elements */}
            {districts.map((district) => (
              <MenuItem key={district} value={district}>
                {district}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={typographyStyle}>
            Name of the Head/Head of Department of the Educational Institution:
          </InputLabel>
          <TextField
            name="institutehead"
            value={formData.institutehead}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={errors.institutehead}
            helperText={helperText.institutehead}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
  <InputLabel style={typographyStyle}>
    Contact Number of the Head/Head of Department (PTCL):
  </InputLabel>
  <TextField
    name="instituteptcl"
    value={formData.instituteptcl}
    onChange={(event) => {
      const inputValue = event.target.value.replace(/\D/g, ''); // Filter out non-digit characters
      setFormData({
        ...formData,
        instituteptcl: inputValue
      });
    }}
    fullWidth
    variant="outlined"
    placeholder="02134504225"
    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Allow only numeric input
    error={errors.instituteptcl}
    helperText={helperText.instituteptcl}
  />
</div>

<div style={{ marginBottom: "1rem" }}>
  <InputLabel style={typographyStyle}>
    Contact Number of the Head/Head of Department (Mobile):
  </InputLabel>
  <TextField
  name="institutemobile"
  value={formData.institutemobile}
  onChange={(event) => {
    const inputValue = event.target.value.replace(/\D/g, ''); // Filter out non-digit characters
    setFormData({
      ...formData,
      institutemobile: inputValue
    });
    // Clear the error state
    setErrors({ ...errors, institutemobile: false });
  }}
  fullWidth
  variant="outlined"
  placeholder="03001234567" // Adding placeholder
  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Allow only numeric input
  error={errors.institutemobile}
  helperText={helperText.institutemobile}
/>
</div>


        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={typographyStyle}>
            Degree/Course in which the student is enrolled:
          </InputLabel>
          <TextField
            name="institutecourse"
            value={formData.institutecourse}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={errors.institutecourse}
            helperText={helperText.institutecourse}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={typographyStyle}>
            Bank Account Title of the Educational Institution:
          </InputLabel>
          <TextField
            name="institutebank"
            value={formData.institutebank}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={errors.institutebank}
            helperText={helperText.institutebank}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={typographyStyle}>
            NTN (National Tax Number) of the Educational Institution:
          </InputLabel>
          <TextField
            name="institutentn"
            value={formData.institutentn}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={errors.institutentn}
            helperText={helperText.institutentn}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
          <Typography style={typographyStyle}>
            Duration of the Degree/Course
          </Typography>
          <InputLabel style={typographyStyle}>Year (For e.g 02)</InputLabel>
          <TextField
    name="instituteyear"
    value={formData.instituteyear}
    onChange={handleYearChange} // Use handleYearChange instead of handleChange
    fullWidth
    variant="outlined"
    error={errors.instituteyear}
    helperText={helperText.instituteyear}
  />
          <InputLabel style={typographyStyle}>Month (For e.g 10)</InputLabel>
          <TextField
            name="institutemonth"
            value={formData.institutemonth}
            onChange={handleYearChange} // Use handleYearChange instead of handleChange
            fullWidth
            variant="outlined"
            error={errors.institutentn}
            helperText={helperText.institutemonth}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={typographyStyle}>
            Roll Number / Registration Number (required)
          </InputLabel>
          <TextField
            name="instituterollnumber"
            value={formData.instituterollnumber}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={errors.instituterollnumber}
            helperText={helperText.instituterollnumber}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
  <InputLabel style={typographyStyle}>
    Date of Commencement of Degree/Course (For e.g 05,2022) <br />
    (MM,YYYY)
  </InputLabel>
  <TextField
    name="instituteSdate"
    value={formData.instituteSdate}
    onChange={(event) => {
      const inputValue = event.target.value.replace(/\D/g, ''); // Filter out non-digit characters
      const formattedValue = inputValue.slice(0, 2) + (inputValue.length > 2 ? ',' + inputValue.slice(2) : ''); // Insert comma after the first two digits
      setFormData({
        ...formData,
        instituteSdate: formattedValue
      });
    }}
    fullWidth
    variant="outlined"
    inputProps={{ maxLength: 7 }} // Limit maximum length to 6 characters
    error={errors.instituteSdate}
    helperText={helperText.instituteSdate}
  />
</div>

        <div style={{ marginBottom: "1rem" }}>
          <InputLabel style={typographyStyle}>
            Expected Date of Completion of Degree/Course (For e.g 04,2024)<br />
          </InputLabel>
          <TextField
            name="instituteEdate"
            value={formData.instituteEdate}
            onChange={(event) => {
              const inputValue = event.target.value.replace(/\D/g, ''); // Filter out non-digit characters
              const formattedValue = inputValue.slice(0, 2) + (inputValue.length > 2 ? ',' + inputValue.slice(2) : ''); // Insert comma after the first two digits
              setFormData({
                ...formData,
                instituteEdate: formattedValue
              });
            }}
            fullWidth
            variant="outlined"
            inputProps={{ maxLength: 7 }} // Limit maximum length to 6 characters
            error={errors.instituteEdate}
            helperText={helperText.instituteEdate}
          />
        </div>

        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            gap: "0.5rem",
            justifyContent: "space-between",
          }}
        >
          <Button variant="contained" color="secondary" onClick={handleBack}>
            Back
          </Button>
          <Button
  variant="contained"
  color="primary"
  onClick={handleNext}
  disabled={areRequiredFieldsEmpty()} // Disable the button if any required field is empty
>
  Next
</Button>
        </div>
      </Container>
    </>
  );
};

export default CurrentInstitute;
