import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Checkbox,
  TextField,
  InputLabel,
  FormControlLabel,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";
import axios from "axios";

const districts = [
  'Badin',
  'Dadu',
  'Ghotki',
  'Hyderabad',
  'Jacobabad',
  'Jamshoro',
  'Karachi (Central)',
  'Karachi (East)',
  'Karachi (Keamari)',
  'Karachi (Korangi)',
  'Karachi (Malir)',
  'Karachi (South)',
  'Karachi (West)',
  'Kashmore @ Kandhkot',
  'Khairpur',
  'Larkana',
  'Matiari',
  'MirpurKhas',
  'Naushahro Feroze',
  'Qambar Shahdadkot',
  'Sanghar',
  'Shaheed BenazirAbad',
  'Shikarpur',
  'Sujawal',
  'Sukkur',
  'Tando Allahyar',
  'Tando Muhammad Khan',
  'Tharparkar',
  'Thatta',
  'Umerkot'
];

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: "red",
    fontSize: "0.8rem",
  },
}));

const ProvinceSelect = ({ formData, setFormData, nextStep }) => {
  const classes = useStyles();
  const [selectedProvince, setSelectedProvince] = useState("");
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null); // Initialize file state variable
  const [isChecking, setIsChecking] = useState(false); // Add state for loading state of the button
  const handleCheckboxChange = (event) => {
    const province = event.target.checked ? "Sindh" : ""; 
    setSelectedProvince(province); 
    setFormData({ ...formData, province }); 
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      setFormData((prevFormData) => ({
        ...prevFormData,
        file: file,
      }));
    } else {
      setFile(null);
      console.error("Please select a file.");
    }
  };

  const handleNext = async () => {
    console.log(formData);
    if (selectedProvince === "") {
      setErrors({ province: true });
      return;
    }
  
    const requiredFields = [
      // Add other required fields here
      "email",
      "cnic",
    ];
  
    const missingFields = requiredFields.filter(
      (fieldName) => !formData[fieldName] || formData[fieldName].trim() === ""
    );
  
    if (missingFields.length > 0) {
      const newErrors = {};
      missingFields.forEach((fieldName) => {
        newErrors[fieldName] = true;
      });
  
      setErrors(newErrors);
      return;
    }
  
    try {
      const response = await axios.post("https://backend.peef.form-seef.com/api/check-email-cnic", {
        email: formData.email,
        cnic: formData.cnic,
      });
      
      // Log the response from the backend
      console.log("Response from backend:", response);
      
      if (response.status === 200) {
        // Success response
        const emailMessage = response.data.message.email;
        const cnicMessage = response.data.message.cnic;
        
        if (emailMessage && cnicMessage) {
          // Both email and CNIC exist
          Swal.fire({
            icon: 'info',
            title: 'Alert!',
            html: `${emailMessage}<br>${cnicMessage}`,
          });
        } else if (emailMessage) {
          // Only email exists
          Swal.fire({
            icon: 'info',
            title: 'Alert!',
            text: emailMessage,
          });
        } else if (cnicMessage) {
          // Only CNIC exists
          Swal.fire({
            icon: 'info',
            title: 'Alert!',
            text: cnicMessage,
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Neither email nor CNIC exists
        // Swal.fire({
        //   icon: 'info',
        //   title: 'Neither email nor CNIC exists',
        // });
        nextStep();
      } 
    }
    
    
    
    
  };
  
    
    
    
 
  
  

  return (
    <>
      <Typography
        variant="h6"
        style={{
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "1rem",
        }}
      >
        Select the Province.
      </Typography>

      {/* Checkbox for selecting the province */}
      <Container>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedProvince === "Sindh"}
              onChange={handleCheckboxChange}
              color="primary"
            />
          }
          label="Sindh"
        />
      </Container>

      {/* Render the error message for the required fields */}
      {errors.province && (
        <Typography variant="body2" className={classes.errorMessage}>
          Province is required.
        </Typography>
      )}

      {/* Section A heading */}
      <Typography
        variant="h6"
        style={{
          fontFamily: "Arial, sans-serif",
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "1rem",
        }}
      >
        Section A: <br />
        (Student Information)
      </Typography>

      <Container style={{ fontFamily: "Arial, sans-serif", padding: "1rem" }}>
      {/* Student Name */}
        <div style={{ marginBottom: "1rem" }}>
          <InputLabel>Student Name</InputLabel>
          <TextField
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            required
            error={errors.name} // Add error prop to indicate error state
            helperText={errors.name && !formData.name ? "This field is required." : ""}
          />
        </div>

        {/* Date of Birth */}
        <div style={{ marginBottom: "1rem" }}>
  <InputLabel>Date of Birth (DD-MM-YYYY)</InputLabel>
  <TextField
    name="dateOfBirth"
    value={formData.dateOfBirth}
    onChange={(event) => {
      let inputValue = event.target.value.replace(/[^0-9-]/g, '');

      // Ensure only numeric characters and hyphens are allowed
      inputValue = inputValue.slice(0, 10); // Limit input length to 10 characters

      // Insert hyphens after the 2nd and 5th characters
      if (inputValue.length > 2 && inputValue.charAt(2) !== '-') {
        inputValue = inputValue.slice(0, 2) + '-' + inputValue.slice(3);
      }
      if (inputValue.length > 5 && inputValue.charAt(5) !== '-') {
        inputValue = inputValue.slice(0, 5) + '-' + inputValue.slice(6);
      }

      // Update the field value
      setFormData({ ...formData, dateOfBirth: inputValue });
    }}
    fullWidth
    variant="outlined"
    required
    error={errors.dateOfBirth} // Add error prop to indicate error state
    helperText={errors.dateOfBirth && !formData.dateOfBirth ? "Invalid date format (DD-MM-YYYY)" : ""}
    placeholder="DD-MM-YYYY"
    inputProps={{
      maxLength: 10,
      pattern: "\\d{2}-\\d{2}-\\d{4}",
    }}
  />
</div>



{/* CNIC */}


<div style={{ marginBottom: "1rem" }}>
  <InputLabel>CNIC / B Form No. (4XXXX-XXXXXXX-X)</InputLabel>
  <TextField
    name="cnic"
    value={formData.cnic}
    onChange={(event) => {
      let inputValue = event.target.value.replace(/[^0-9]/g, '');

      // Insert hyphens after the 5th and 12th characters
      if (inputValue.length > 5) {
        inputValue = inputValue.slice(0, 5) + '-' + inputValue.slice(5);
      }
      if (inputValue.length > 12) {
        inputValue = inputValue.slice(0, 13) + '-' + inputValue.slice(13);
      }

      // Update the field value
      setFormData({ ...formData, cnic: inputValue });
    }}
    fullWidth
    variant="outlined"
    required
    placeholder={formData.cnic ? '' : "4XXXX-XXXXXXX-X"}
    error={errors.cnic} // Add error prop to indicate error state
    helperText={errors.cnic && !formData.cnic ? "Invalid CNIC format (4XXXX-XXXXXXX-X)" : ""}
    inputProps={{
      maxLength: 15, // Updated max length to accommodate hyphens
      pattern: "\\d{5}-\\d{7}-\\d{1}", // Updated pattern
    }}
  />
</div>

<div style={{ marginBottom: "1rem" }}>
  <InputLabel>Gender</InputLabel>
  <TextField
    name="gender"
    value={formData.gender}
    onChange={handleChange}
    select // Add the select prop to convert it into a dropdown
    fullWidth
    variant="outlined"
    required
    error={errors.gender} // Add error prop to indicate error state
    helperText={errors.gender && !formData.gender ? "This field is required." : ""}
  >
    
    {/* Add MenuItem elements to populate the options in the dropdown */}
    <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          
    {/* Add more MenuItem elements for each district option */}
  </TextField>
</div>

<div style={{ marginBottom: "1rem" }}>
  <InputLabel>Student Domicile</InputLabel>
  <TextField
    name="studentDomicile"
    value={formData.studentDomicile}
    onChange={handleChange}
    select // Add the select prop to convert it into a dropdown
    fullWidth
    
    variant="outlined"
    required
    error={errors.studentDomicile} // Add error prop to indicate error state
    helperText={errors.studentDomicile && !formData.studentDomicile ? "This field is required." : ""}
  >
     {/* Add an empty disabled MenuItem for the top label */}
     <MenuItem disabled value="">
      Please select district
    </MenuItem>
    {/* Add MenuItem elements to populate the options in the dropdown */}
   {/* Map over the districts array to generate MenuItem elements */}
   {districts.map((district) => (
              <MenuItem key={district} value={district}>
                {district}
              </MenuItem>
            ))}
    {/* Add more MenuItem elements for each district option */}
  </TextField>
</div>

<div style={{ marginBottom: "1rem" }}>
          <InputLabel>Student's Mobile Number</InputLabel>
          <TextField
            name="studentMobile"
            value={formData.studentMobile}
             // Filter out non-numeric characters
             onChange={(event) => {
              // Remove all non-numeric characters from the input value
              const inputValue = event.target.value.replace(/\D/g, '');
              // Update the formData state with the sanitized value
              setFormData({
                ...formData,
                studentMobile: inputValue
              });
  }}
  fullWidth
  variant="outlined"
  required
  placeholder="03001234567"
  error={errors.studentMobile} // Add error prop to indicate error state
  helperText={errors.studentMobile && !formData.studentMobile ? "This Field is Required" : ""}
  inputProps={{
    maxLength: 12, // Updated max length to accommodate hyphens
    pattern: "\\d{11}", // Updated pattern
  }}
          />
        </div>


<Typography
        variant="h6"
        style={{
          fontFamily: "Arial, sans-serif",
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "1rem",
        }}
      >
        Parents / Guardian Details <br />
        
      </Typography>


<div style={{ marginBottom: "1rem" }}>
          <InputLabel>Father's Name</InputLabel>
          <TextField
            name="fatherName"
            value={formData.fatherName}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            required
            error={errors.fatherName} // Add error prop to indicate error state
            helperText={errors.fatherName && !formData.fatherName ? "This field is required." : ""}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
  <InputLabel>Father CNIC No. (4XXXX-XXXXXXX-X)</InputLabel>
  <TextField
    name="fatherCnic"
    value={formData.fatherCnic}
    onChange={(event) => {
      let inputValue = event.target.value.replace(/[^0-9]/g, '');

      // Insert hyphens after the 5th and 13th characters
      if (inputValue.length > 5) {
        inputValue = inputValue.slice(0, 5) + '-' + inputValue.slice(5);
      }
      if (inputValue.length > 13) {
        inputValue = inputValue.slice(0, 13) + '-' + inputValue.slice(13);
      }

      // Update the field value
      setFormData({ ...formData, fatherCnic: inputValue });
    }}
    fullWidth
    variant="outlined"
    required
    placeholder={formData.fatherCnic  ? '' : "4XXXX-XXXXXXX-X"}
    error={errors.fatherCnic} // Add error prop to indicate error state
    helperText={errors.fatherCnic && !formData.fatherCnic ? "Invalid CNIC format (4XXXX-XXXXXXX-X)" : ""}
    inputProps={{
      maxLength: 15, // Updated max length to accommodate hyphens
      pattern: "\\d{5}-\\d{7}-\\d{1}", // Updated pattern
    }}
  />
</div>
  

<div style={{ marginBottom: "1rem" }}>
          <InputLabel>Father's Occupation</InputLabel>
          <TextField
            name="fatherOccupation"
            value={formData.fatherOccupation}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            required
            error={errors.fatherOccupation} // Add error prop to indicate error state
            helperText={errors.fatherOccupation && !formData.fatherOccupation ? "This field is required." : ""}
          />
        </div>

        <div style={{ marginBottom: "3rem" }}>
          <InputLabel>Father's Monthly Income</InputLabel>
          <TextField
  name="fatherIncome"
  value={formData.fatherIncome}
  onChange={handleChange}
  fullWidth
  variant="outlined"
  required
  error={errors.fatherIncome} // Add error prop to indicate error state
  helperText={errors.fatherIncome && !formData.fatherIncome ? "Invalid Income format" : ""}
  inputProps={{
    maxLength: 10,
    pattern: "\\d{6}",
    onChange: (event) => {
      event.target.value = event.target.value.replace(/[^0-9-]/g, ''); // Filter out non-numeric characters
    }
  }}
  
/>
  </div>

  
  <div style={{ marginBottom: "1rem" }}>
          <InputLabel>Mother's Name</InputLabel>
          <TextField
            name="motherName"
            value={formData.motherName}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            required
            error={errors.motherName} // Add error prop to indicate error state
            helperText={errors.motherName && !formData.motherName ? "This field is required." : ""}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
  <InputLabel>Mother CNIC No. (4XXXX-XXXXXXX-X)</InputLabel>
  <TextField
    name="motherCnic"
    value={formData.motherCnic}
    onChange={(event) => {
      let inputValue = event.target.value.replace(/[^0-9]/g, '');

      // Insert hyphens after the 5th and 13th characters
      if (inputValue.length > 5) {
        inputValue = inputValue.slice(0, 5) + '-' + inputValue.slice(5);
      }
      if (inputValue.length > 13) {
        inputValue = inputValue.slice(0, 13) + '-' + inputValue.slice(13);
      }

      // Update the field value
      setFormData({ ...formData, motherCnic: inputValue });
    }}
    fullWidth
    variant="outlined"
    required
    placeholder={formData.cnic ? '' : "4XXXX-XXXXXXX-X"}
    error={errors.motherCnic} // Add error prop to indicate error state
    helperText={errors.motherCnic && !formData.motherCnic ? "Invalid CNIC format (4XXXX-XXXXXXX-X)" : ""}
    inputProps={{
      maxLength: 15, // Updated max length to accommodate hyphens
      pattern: "\\d{5}-\\d{7}-\\d{1}", // Updated pattern
    }}
  />
</div>     

<div style={{ marginBottom: "1rem" }}>
          <InputLabel>Parents / Guardian Mobile Number</InputLabel>
          <TextField
            name="parentMobile"
            value={formData.parentMobile}
             // Filter out non-numeric characters
             onChange={(event) => {
   
              const inputValue = event.target.value.replace(/\D/g, '');
   
   
    // Update the field value
    setFormData({ ...formData, parentMobile: inputValue });
  }}
  fullWidth
  variant="outlined"
  required
  placeholder="03001234567"
  error={errors.parentMobile} // Add error prop to indicate error state
  helperText={errors.parentMobile && !formData.parentMobile ? "Invalid mobile number format" : ""}
  inputProps={{
    maxLength: 12, // Updated max length to accommodate hyphens
    pattern: "\\d{11}", // Updated pattern
  }}
          />
        </div>


<Typography
        variant="h6"
        style={{
          fontFamily: "Arial, sans-serif",
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "1rem",
        }}
      >
        Postal / Residential Details <br />
        
      </Typography>

      <div style={{ marginBottom: "1rem" }}>
          <InputLabel>Complete Address (for correspondence)</InputLabel>
          <TextField
            name="address"
            value={formData.address}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            required
            error={errors.address} // Add error prop to indicate error state
            helperText={errors.address && !formData.address ? "This field is required." : ""}
          />
        </div>
     
     
      <div style={{ marginBottom: "1rem" }}>
  <InputLabel>District of Residence</InputLabel>
  <TextField
    name="residenceDistrict"
    value={formData.residenceDistrict}
    onChange={handleChange}
    select // Add the select prop to convert it into a dropdown
    fullWidth
    
    variant="outlined"
    required
    error={errors.residenceDistrict} // Add error prop to indicate error state
    helperText={errors.residenceDistrict && !formData.residenceDistrict ? "This field is required." : ""}
  >
     {/* Add an empty disabled MenuItem for the top label */}
     <MenuItem disabled value="">
      Please select district
    </MenuItem>
    {/* Add MenuItem elements to populate the options in the dropdown */}
   {/* Map over the districts array to generate MenuItem elements */}
   {districts.map((district) => (
              <MenuItem key={district} value={district}>
                {district}
              </MenuItem>
            ))}
    {/* Add more MenuItem elements for each district option */}
  </TextField>
</div>

<div style={{ marginBottom: "1rem" }}>
          <InputLabel>Email</InputLabel>
          <TextField
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            required
            error={errors.email} // Add error prop to indicate error state
            helperText={errors.email && !formData.email ? "This field is required." : ""}
          />
        </div>

        <div style={{ marginBottom: "1rem" }}>
  <InputLabel>Upload Student's Picture</InputLabel>
  <input
    type="file"
    onChange={handleFileChange}
    style={{ display: "block" }}
  />
  {/* Display preview of selected file if available */}
  {file && (
    <>
      {file.type.startsWith("image/") ? (
        <img
          src={URL.createObjectURL(file)}
          alt="Selected"
          style={{ marginTop: "0.5rem", maxWidth: "100%", maxHeight: "200px" }}
        />
      ) : (
        <p>File type: {file.type}</p>
      )}
    </>
  )}
</div>


      </Container>

      {/* Render the StudentDetails component */}
      {/* <StudentDetails formData={formData} setFormData={setFormData} errors={errors} /> */}

      {/* Next Button */}
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
         {/* Button for going to the next step */}
         <Button
  variant="contained"
  color="primary"
  onClick={handleNext}
  fullWidth
  style={{ marginBottom: "2rem", marginTop: "1rem" }}
  // Disable the button if any required fields are empty or loading state is true
  disabled={
    isChecking ||
    !selectedProvince ||
    Object.keys(errors).some((key) => errors[key]) ||
    !formData.name ||  // Example: Add more required fields here
    !formData.dateOfBirth ||
    !formData.cnic ||
    !formData.gender ||
    !formData.studentDomicile ||
    !formData.studentMobile ||
    !formData.fatherName ||
    !formData.fatherCnic ||
    !formData.fatherOccupation ||
    !formData.fatherIncome ||
    !formData.motherName ||
    !formData.motherCnic ||
    !formData.parentMobile ||
    !formData.address ||
    !formData.residenceDistrict ||
    !formData.email
    // !formData.file
  }
>
  {/* Conditional rendering based on the loading state */}
  {isChecking ? ( 
    // Display circular loading icon while checking
    <CircularProgress size={24} color="inherit" />
  ) : (
    // Display "Next" text when not checking
    "Next"
  )}
</Button>

      </div>
    </>
  );
};

export default ProvinceSelect;
