import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { TextField, IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import generatePDF from "./singlePDF"; // Import the generatePDF function

function ResultTableContainer() {
  const [rowData, setRowData] = useState([]);
  const [formDataLength, setFormDataLength] = useState(0);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    axios
      .get("https://backend.peef.form-seef.com/api/students")
      .then((response) => {
        console.log("Response Data:", response.data); // Log response data
        if (response && response.data) {
          // Assuming response.data is already an array of objects
          setRowData(response.data.data);
          setFormDataLength(response.data.data.length);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error); // Log error
      });
  }, []);

  const handleDownloadPDF = (data) => {
    // Call generatePDF function with row data
    generatePDF(data); // Pass an array containing the selected row
  };

  const filteredRowData = useMemo(() => {
    if (!searchText) {
      return rowData;
    }
    const lowerCaseSearchText = searchText.toLowerCase();
    return rowData.filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(lowerCaseSearchText)
      )
    );
  }, [rowData, searchText]);

  

  const columns = [
    { field: "id", headerName: "SR. NO.", width: 70 },
    { 
      field: "name", 
      headerName: "NAME OF STUDENT", 
      width: 200,
      wrap: true 
    },
    { field: "gender", headerName: "GENDER", width: 150 },
    { field: "cnic", headerName: "STUDENT CNIC / B FORM NO.", width: 100  },
    {
      field: "dateOfBirth",
      headerName: "STUDENT DATE OF BIRTH (dd/mm/yy)",
      width: 200,
    },
    {
      field: "fatherName",
      headerName: "FATHER'S / GUARDIAN'S NAME",
      width: 200,
    },{
      field: "fatherCnic",
      headerName: "FATHER'S / GUARDIAN'S CNIC NO.",
      width: 200,
    },
    { field: "studentDomicile", headerName: "STUDENT DOMICILE", width: 150, },
    { field: "address", headerName: "RESIDENTIAL ADDRESS OF STUDENT", width: 200 },
    { field: "parentMobile", headerName: "FATHER'S MOBILE NO", width: 200 },
    { field: "studentMobile", headerName: "STUDENT'S MOBILE NO", width: 200 },
    { field: "matricSubject", headerName: "LAST DEGREE ATTENDED", width: 150 },
    { field: "matricrollNumber", headerName: "ROLL NO.", width: 150 },
    { field: "previnstitutename", headerName: "NAME OF PREVIOUS INSTITUTION", width: 150 },
    { field: "previnstitutedistrict", headerName: "DISTRICT OF INSTITUTION", width: 150 },
    { field: "previnstitutetype", headerName: "TYPE OF PREVIOUS INSTITUTION (GOVT. /PRIVATE)", width: 150 },
    { field: "matricTotalMarks", headerName: "TOTAL MARKS", width: 150 },
    { field: "matricmarksObtained", headerName: "MARKS OBTAINED", width: 150 },
    { field: "matricpercentage", headerName: "PERCENTAGE OF MARKS OBTAINED", width: 150 },
    { field: "matricYear", headerName: "YEAR OF PASSING", width: 150 },
    { field: "fatherOccupation", headerName: "OCCUPTAION", width: 150 },
    { field: "fatherIncome", headerName: "MONTHLY INCOME (Rs.)", width: 150 },
    { field: "institute", headerName: "COMPLETE NAME OF INSTITUTION WHERE STUDENT IS CURRENTLY ENROLLED", width: 150 },
    { field: "instituteaddress", headerName: "COMPLETE ADDRESS OF INSTITUTION", width: 150 },
    { field: "currentinstitutedistrict", headerName: "DISTRICT OF INSTITUTION (MANDATORY)", width: 150 },
    { field: "institutecourse", headerName: "NAME OF DEGREE COURSE", width: 150 },
    { field: "instituteSdate", headerName: "START DATE (MM/YY)", width: 150 },
    { field: "instituteEdate", headerName: "DATE OF COMPLETION (MM/YY)", width: 150 },
    { field: "institutehead", headerName: "NAME OF HEAD OF INSTITUTION", width: 150 },
    { field: "institutebank", headerName: "TITLE OF ACCOUNT OF HEAD OF INSTITUTION", width: 150 },
    { field: "institutemobile", headerName: "CONTACT NUMBER OF HEAD OF INSTITUTION", width: 150 },
    { field: "selectedSpecialQuota", headerName: "SPECIAL QUOTA CATEGORY", width: 150 },
    { field: "instituterollnumber", headerName: "REG NO. / ROLL NO.", width: 150 },
    { field: "isStayingInHostel", headerName: "STUDENT CATEGORY (DAY SCHOLAR / BOARDER)", width: 150 },
    { field: "isReceivingScholarship", headerName: "AVAILING ANY OTHER SCHOLARSHIP(S) (YES / NO)", width: 150 },
    {
      field: "download",
      headerName: "Download PDF",
      width: 150,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleDownloadPDF(params.row)}
          aria-label="download pdf"
        >
          <DownloadIcon />
        </IconButton>
      ),
    },
    // { 
    //   field: "file_name",
    //   headerName: "RESULT",
    //   width: 200,
    //   renderCell: (params) => (
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       onClick={() => handleFilePreview(params.row.file_path, params.row.file_name)}
    //     >
    //       Preview File
    //     </Button>
    //   )
    // }
  ];

  return (
    <div className="flex flex-col justify-center items-center mt-6">
      <div className=" bg-gray-300  rounded-lg  shadow-md p-4 hover:transform hover:-translate-y-2 hover:bg-slate-300 hover:transition hover:duration-300 hover:ease-in-out flex flex-col items-center cursor-pointer">
        {formDataLength ? (
          <div className="w-20 h-10 flex justify-center items-center text-3xl font-bold">
            {formDataLength}
          </div>
        ) : (
          <div className="w-20 h-10 flex justify-center items-center text-3xl font-bold">
            Loading...
          </div>
        )}

        <div className="p-2 text-center">
          <h2 className="font-bold mb-2">Form Submitted</h2>
          <p className="text-gray-700 text-base">Total Form submitted</p>
        </div>
      </div>

      <div className="" style={{ width: "100%" }}>
        <div className="p-3 ml-4">
          <TextField
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            variant="outlined"
            className="bg-white "
          />
        </div>
        <div className="bg-white items-center justify-center ml-4 p-3 "  style={{ height: "70vh" , width: "180vh" }}>
          <DataGrid
            rows={filteredRowData}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
            pagination
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50]}
            disableSelectionOnClick
          />
        </div>
      </div>
    </div>
  );
}

export default ResultTableContainer;