import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AdminPage from "../../src/adminpanel/index";
// import Header from "./Header"; // Import the Header component

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    padding: theme.spacing(3),
    background: theme.palette.background.default,
  },
  container: {
    maxWidth: 600,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.grey[300],
    boxShadow: theme.shadows[7],
    marginTop: theme.spacing(5), // Add margin top here
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0), // Decrease margin top for small screens
    },
  },
  logo: {
    width: 80,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1), // Added margin right to create a gap
    },
  },
  department: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(10),
      "& > *": {
        fontSize: "1.2rem", // Increase the font size for LG screens
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },


  title: {
    marginBottom: theme.spacing(5),
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.9rem", // Decrease font size for smaller screens
      fontWeight:"bold"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem", // Decrease font size for smaller screens
      fontWeight:"bold"
    },
  },
  form: {
    width: "100%",
    marginBottom: theme.spacing(3),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
}));

function Login() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);

  function handleSubmit(event) {
    event.preventDefault();

    if (email === "test@example.com" && password === "password123") {
      setIsLoggedIn(true);
      setError(null);
      console.log("Login successful");
      navigate("/adminpanel");
    } else {
      setIsLoggedIn(false);
      setError("Invalid email or password");
      console.log("Login failed");
    }
  }

  function handleForgotPassword(event) {
    event.preventDefault();
    console.log("Forgot password");
  }

  if (isLoggedIn) {
    return (
      <div>
        <AdminPage />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src="/logo.png" alt="Logo" style={{ width: "100%" }} />
        </div>
        <div className={classes.department}>
          <Typography variant="h6" component="h2" style={{ fontWeight: "bold" }}>
            GOVERNMENT OF SINDH
          </Typography>
          <Typography variant="subtitle1" component="p">
            COLLEGE EDUCATION DEPARTMENT
          </Typography>
          <Typography variant="subtitle2" component="p">
            PUNJAB EDUCATIONAL ENDOWMENT FUND
          </Typography>
          {/* <Typography variant="subtitle2" component="p">
            REGISTERED VIDE TRUST DEED NO. 625/2020
          </Typography> */}
        </div>
      </header>
      <Container className={classes.container}>
        <Typography variant="h4" component="h1" className={classes.title}>
          Log in to your account
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            className={classes.input}
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth
          />
          <TextField
            className={classes.input}
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            Log in
          </Button>
        </form>
        {error && <p className={classes.error}>{error}</p>}
        <div>
          <a
            href="#"
            className={classes.link}
            onClick={handleForgotPassword}
          >
            Forgot password?
          </a>
        </div>
      </Container>
    </div>
  );
}

export default Login;
