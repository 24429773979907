import React, { useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {Button} from "@material-ui/core";
export default function Form(props) {
  
  const [formData] = useState({ ...props.formData });

  // console.log("in financialStep");
  // console.log(formData);

  //1. Personal
  const tableData2 = [
    [
      "Student Name",
      ":",
      formData?.name,

      "Date of Birth",
      ":",
      formData?.dateOfBirth,
    ],
    ["CNIC / BForm No.", ":", formData?.cnic,
    "Gender", ":", formData?.gender],
    [
      "Father / Guardian Name",
      ":",
      formData?.fatherName,
      "Father CNIC No.",
      ":",
      formData?.fatherCnic,
    ],
    [
      "Mother Name",
      ":",
      formData?.motherName,
      "Mother CNIC No.",
      ":",
      formData?.motherCnic,
    ],
    ["House Address", ":", formData?.address],
    [
      "Residential District",
      ":",
      formData?.residenceDistrict,
      "Email",
      ":",
      formData?.email,
    ],
    [
      "Mobile No. (Parents)",
      ":",
      formData?.parentMobile,
      "Mobile No. (Student)",
      ":",
      formData?.studentMobile,
    ],
  ]; //1. Personal
  const matricData = [
    [
      "Passing Year",
      ":",
      formData?.matricYear,

      "Roll No.",
      ":",
      formData?.matricrollNumber,
      "Total Marks",
      ":",
      formData?.matricTotalMarks,
      "Marks Obtained.",
      ":",
      formData?.matricmarksObtained,
      "Percentage.",
      ":",
      formData?.matricpercentage,
      
    ],
  ];
  const interData = [
    [
      "Passing Year",
      ":",
      formData?.interYear,

      "Roll No.",
      ":",
      formData?.interRollNumber,
      "Total Marks",
      ":",
      formData?.interTotalMarks,
      "Marks Obtained.",
      ":",
      formData?.intermarksObtained,
      "Percentage.",
      ":",
      formData?.interpercentage,
    ],
  ];
  const previnstName = [
    [
      "Complete Name of Institute",
      ":",
      formData?.previnstitutename,
      "Institution Type",
      ":",
      formData?.previnstitutetype,
      // formData?.previnstituteaddress,
      ],
  ];
  const previnstData = [
    [
      "Complete Address of Institute",
      ":",
      // formData?.previnstitutename,
      formData?.previnstituteaddress,

      "District",
      ":",
      formData?.previnstitutedistrict,
    ],
  ];

  const currentinstName = [
    [
                                            "Full Name of Institute",
                                            ":",
                                            formData?.institute,

                                          ],
                                          [
                                            "Complete Address of Institute",
                                            ":",
                                            formData?.instituteaddress,
                                          ],
                                          [
                                            "Name of Principal / Head of Department of the Institute",
                                            ":",
                                            formData?.institutehead,
                                          ],
  ]; 
  const currentinstData1 = [
    
      [
        "Contact No. of Principal / Head of Department of the Institute (PTCL)",
        ":",
        formData?.instituteptcl,
      
      
                                            "Mobile",
                                            ":",
                                            formData?.institutemobile,
      ],
                                          [
                                            "Degree / Course (Student Enrolled in)",
                                            ":",
                                            formData?.institutecourse,
        
        
                                            "Bank Account Title of Institute",
                                            ":",
                                            formData?.institutebank,
                                          ],
  
                                        ]; 
  const currentinstData = [
   
    ["NTN no. of Institute", ":", formData?.institutentn],
   
  ];
  const currentinstData2 = [
      [
      "Duration of Degree / Course (Year)",
      ":",
      formData?.instituteyear,
      "Month",
      ":",
      formData?.institutemonth,
      "Roll / Reg. No.",
      ":",
      formData?.instituterollnumber,
    ],
   
  ];
  const currentinstData3 = [
     [
      "Degree / Course Start Date",
      ":",
      formData?.instituteSdate,
      "Expected Completion Date of Degree / Course",
      ":",
      formData?.instituteEdate,
    ],
  ];

  const specialQuotaData = [
    ["1", "Orphan Student (Student whose parents are not alive)","","Attach the photocopy of Death certificate OR verification of Father from Secretary Union Council / School Head Master"],
    ["2", "Children of Grade 1-4 Govt. Servant","","Attach the Pay Slip or Certificate issue from the department. Incase of Pensioner attach the photocopy of pension book / bank statement."],
    ["3", "Student Belonging to Minorities","","Attach the photocopy of certificate issued by the Head / Representative of concerned religious department."],
    ["4 ", "Disabled Student","","Attach the photocopy of Disability certificate issued by DHQ, THQ Hospital, or RHC"],
    ["5", "Child of the civilians who were martyred in terrorist attacks","","Attach the photocopy of death certificate issued by union council on which reason of death is mentioned. Get verification of martyrdom of Father/Mother by Head Master/Imam Masjid/Local Police Station."],
    
  ];



  const document = [
    ["* Income Affidavit on Rs 50/- Stamp Paper as per Format provided by PEEF duly signed by parents, is attached", ""],
    ["* Attested copy of Pay Slip / Pension book of serving / retired Govt. servant is attached.", ""],
    ["* Attested copy of Matric/Intermediate certificate/marksheet is attached", ""],
    ["* Passport Size Photograph(Blue Background) is pasted on the form ", ""],
    ["* Incase of Special Quota category, relevant documents/certificate are attached", ""],
    ["* For Contact, contact no. of personal or house or any family member has been written (It is necessary because this office will remain in contact with you through that contact no.)", ""],
  ];
  
  const handleDownloadPDF = () => {
    // setFormData(formData);
    // Add the province checkboxes
    const province = [
      // { name: "AJK", label: "AJK" },
      // { name: "Balochistan", label: "Balochistan" },
      // { name: "FormerFata", label: "Former\nFATA" },
      // { name: "GilgitBaltistan", label: "Gilgit\nBaltistan" },
      // { name: "ICT", label: "ICT" },
      // { name: "KhyberPakhtunKhwah", label: "KPK" },
      { name: "Sindh", label: "Sindh" },
    ];
    const matricsub = [
      { name: "Arts", label: "Arts / \n G. Sci." },
      { name: "Science", label: "Science" },
    ];
    const inter = [
      { name: "Pre-Medical", label: "Pre Med" },
      { name: "Pre-Engineering", label: "Pre Eng" },
      { name: "Commerce", label: "Commerce" },
      { name: "General Science", label: "Gen.Sci \n/ ICS" },
      { name: "Humanities", label: "Humanities" },
    ];
    const Hostel = [
      { name: "Yes", label: "Yes" },
      { name: "No", label: "No" },
    ];
    const Scholarship = [
      { name: "Yes", label: "Yes" },
      { name: "No", label: "No" },
    ];

 const specialQuota = [
      { name: "OrphanStudent", label: "" },
      { name: "ChildrenOfGovtServant", label: "" },
      { name: "StudentsBelongingToMinorities", label: "" },
      { name: "DisabledStudents", label: "" },
      { name: "ChildrenOfMartyredCivilians", label: "" },
      ];

// Step 2: Load the Checkbox Image
const tickImageUrl = "/check-tick-icon.png"; // Replace with the actual path to your checkbox image
let checkboxImage;

const loadCheckboxImage = () => {
  const imgData = new Image();
  imgData.src = tickImageUrl;
  checkboxImage = imgData;
};

loadCheckboxImage(); // Call this function to load the image


    // Create a new PDF document
    const doc = new jsPDF({
      orientation: "portrait", // or "landscape"
      unit: "mm",
      format: "legal", // Set the paper size to legal
    });

    // Add a black background box with white text
    doc.setFillColor(0, 0, 0); // Black color for background
    doc.setTextColor(255, 255, 255); // White color for text
    doc.rect(5, 5, 200, 12, "F"); // Draw a filled rectangle

    // Add the text inside the box
    doc.setFontSize(16);
    doc.text("THE PUNJAB EDUCATIONAL ENDOWMENT FUND (PEEF)", 105, 12, {
      align: "center",
    });

    // doc.setTextColor(0, 0, 0); // Set text color back to black
    // doc.setFontSize(16);
    // doc.text("Personal Information", 20, 40);

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0); // Set text color back to black
    doc.setFont("helvetica", "bold"); // Set font to bold
    doc.text("Mark the relevant field", 5, 24);
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal"); // Set font back to normal
    const xStart = 75; // X position to start rendering checkboxes
    const yStart = 21.5; // Y position to start rendering checkboxes
    const checkboxGap = 28; // Gap between checkboxes

    // Render the province checkboxes in a row
    province.forEach((province, index) => {
      const xPosition = xStart + index * checkboxGap;
      const yPosition = yStart + 1.5; // Increase the yPosition to avoid overlapping with the province label
      doc.text(province.label, xPosition, yPosition);

      // Check if the current province matches the selected province
      const isChecked = formData.province === province.name;
      console.log("formData:", formData);
      // console.log("formData.province:", formData.province);
      // console.log("province.name:", province.name);
      // console.log("isChecked:", isChecked);
      // Render the checkboxes in the PDF
      doc.setDrawColor(0); // Black color for checkbox border
      doc.rect(xPosition - 6, yStart - 2, 5, 5, "S"); // Draw the checkbox border
      // if (isChecked) {
      //   doc.setFillColor(0); // Fill the checkbox with black color if checked
      //   doc.rect(xPosition - 4, yStart - 1, 5, 5, "FD"); // Draw the filled checkbox
      // }

   // If the checkbox is checked, place the tick image inside the checkbox
   if (isChecked) {
    doc.addImage(checkboxImage, xPosition - 6, yStart - 2, 5, 5);
  }

    });

    // Add a black background box with white text
    doc.setFillColor(0, 0, 0); // Black color for background
    doc.rect(3, 30, 46, 8, "F"); // Draw a filled rectangle
    doc.setFont("helvetica", "bold"); // Set font to bold

    // Add the text inside the box
    doc.setTextColor(255, 255, 255); // White color for text
    doc.setFontSize(8);

    doc.text("Section (A) Students Information", 26, 35, {
      align: "center",
    });
    doc.setTextColor(0, 0, 0); // White color for text
    doc.setFontSize(16);
    doc.text("Application Form for the PEEF Scholarship", 110, 35, {
      align: "center",
    });

    // Add a black background box with white text
    doc.setFillColor(0, 0, 0); // Black color for background
    doc.rect(181, 30, 30, 8, "F"); // Draw a filled rectangle

    // Add the text inside the box
    doc.setTextColor(255, 255, 255); // White color for text
    doc.setFontSize(10);
    // doc.setFont("helvetica", "normal"); // Set font to bold
    doc.text("OP / Areas 2022", 195.5, 35, {
      align: "center",
    });

    // doc.line(10, 150, 200, 150);

    doc.autoTable({
      body: tableData2,

      startY: 40,
      margin: { left: 5 }, // Set the desired x-position here,

      columnStyles: {
        0: { fillColor: "#fff", fontSize: 10, cellWith: 20 },
        1: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        2: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 50,
          fontStyle: "bold",
        },
        3: { fillColor: "#fff", fontSize: 10, cellWith: 10 },
        4: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        5: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 30,
          fontStyle: "bold",
        },
      },
    });

    
    doc.rect(181, 40, 30, 35);
    doc.setFont("helvetica", "normal"); // Set font to bold
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0); // White color for text
    doc.text(
      "Photo\nPassport Size\n (Blue Background)",
      195.5,
      55,
      null,
      null,
      "center"
    );
 if (formData.image) {
    const imgData = URL.createObjectURL(formData.image);
    doc.addImage(imgData, 'JPEG', 181.5, 40.5, 29, 34);
  }
    // Add a black background box with white text
    doc.setFillColor(0, 0, 0); // Black color for background
    doc.rect(3, 100, 144, 8, "F"); // Draw a filled rectangle

    // Add the text inside the box
    doc.setTextColor(255, 255, 255); // White color for text
    doc.setFont("helvetica", "bold"); // Set font to bold
    doc.setFontSize(10);
    doc.text(
      " Details of Matriculation / Intermediate Examinations Passed in the Year 2022 (Annual)",
      74,
      105,
      {
        align: "center",
      }
    );

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0); // Set text color back to black
    doc.setFont("helvetica", "bold"); // Set font to bold
    doc.text("(Mark the relevant field)", 100, 116);
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal"); // Set font back to normal
    // const xStart = 20; // X position to start rendering checkboxes
    // const yStart = 35; // Y position to start rendering checkboxes
    // const checkboxGap = 28; // Gap between checkboxes
    
    doc.setFont("helvetica", "bold"); // Set font to bold
    doc.text("Group (Matric)", 5, 116);
    doc.setFont("helvetica", "normal"); // Set font to bold
    // Render the province checkboxes in a row
    matricsub.forEach((matric, index) => {
      const xPosition = 50 + index * checkboxGap;
      const yPosition = 115 + 2; // Increase the yPosition to avoid overlapping with the province label
      doc.text(matric.label, xPosition, yPosition);

      // Check if the current province matches the selected province
      const isChecked = formData.matricSubject === matric.name;
      // console.log("formData.Subject:", formData.matricSubject);
      // console.log("formData.matricSubject", matric.name);
      // console.log("province.name:", province.name);
      // console.log("isChecked:", isChecked);

      // Render the checkboxes in the PDF
      doc.setDrawColor(0); // Black color for checkbox border
      doc.rect(xPosition - 6, yPosition - 3, 5, 5, "S"); // Draw the checkbox border
      // if (isChecked) {
      //   doc.setFillColor(0); // Fill the checkbox with black color if checked
      //   doc.rect(xPosition - 4, yPosition - 2, 5, 5, "FD"); // Draw the filled checkbox
      // }

 // If the checkbox is checked, place the tick image inside the checkbox
 if (isChecked) {
  doc.addImage(checkboxImage, xPosition - 6, yPosition - 3, 5, 5);
}
   });

    doc.autoTable({
      body: matricData,

      startY: 124,
      margin: { left: 5 }, // Set the desired x-position here,

      columnStyles: {
        0: { fillColor: "#fff", fontSize: 10, cellWith: 35 },
        1: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        2: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 20,
          fontStyle: "bold",
        },
        3: { fillColor: "#fff", fontSize: 10, cellWith: 18 },
        4: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        5: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 20,
          fontStyle: "bold",
        },
        6: { fillColor: "#fff", fontSize: 10, cellWith: 35 },
        7: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        8: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 20,
          fontStyle: "bold",
        },
        9: { fillColor: "#fff", fontSize: 10, cellWith: 35 },
        10: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        11: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 20,
          fontStyle: "bold",
        }, 
        12: { fillColor: "#fff", fontSize: 10, cellWith: 35 },
        13: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        14: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 20,
          fontStyle: "bold",
        },
      },
    });

    doc.setFontSize(11);
    doc.setFont("helvetica", "bold"); // Set font back to normal
    // const xStart = 20; // X position to start rendering checkboxes
    // const yStart = 35; // Y position to start rendering checkboxes
    // const checkboxGap = 28; // Gap between checkboxes
    
    doc.text("Group (Intermediate)", 5, 143);
    doc.setFont("helvetica", "normal"); // Set font back to normal
    // Render the province checkboxes in a row
    inter.forEach((inter, index) => {
      const xPosition = 60 + index * checkboxGap;
      const yPosition = 141 + 1.5; // Increase the yPosition to avoid overlapping with the province label
      doc.text(inter.label, xPosition, 143);

      // Check if the current province matches the selected province
      const isChecked = formData.intermediateSubject === inter.name;

      // Render the checkboxes in the PDF
      doc.setDrawColor(0); // Black color for checkbox border
      doc.rect(xPosition - 6, yPosition - 3, 5, 5, "S"); // Draw the checkbox border
          // if (isChecked) {
      //   doc.setFillColor(0); // Fill the checkbox with black color if checked
      //   doc.rect(xPosition - 4, yPosition - 2, 5, 5, "FD"); // Draw the filled checkbox
      // }

 // If the checkbox is checked, place the tick image inside the checkbox
 if (isChecked) {
  doc.addImage(checkboxImage, xPosition - 6, yPosition - 3, 5, 5);
}
    });

    doc.autoTable({
      body: interData,

      startY: 148,
      margin: { left: 5 }, // Set the desired x-position here,

      columnStyles: {
        0: { fillColor: "#fff", fontSize: 10, cellWith: 35 },
        1: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        2: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 20,
          fontStyle: "bold",
        },
        3: { fillColor: "#fff", fontSize: 10, cellWith: 18 },
        4: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        5: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 20,
          fontStyle: "bold",
        },
        6: { fillColor: "#fff", fontSize: 10, cellWith: 35 },
        7: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        8: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 20,
          fontStyle: "bold",
        },
        9: { fillColor: "#fff", fontSize: 10, cellWith: 35 },
        10: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        11: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 20,
          fontStyle: "bold",
        }, 
        12: { fillColor: "#fff", fontSize: 10, cellWith: 35 },
        13: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        14: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 20,
          fontStyle: "bold",
        },
      },
    });

    doc.setFontSize(13);
    doc.setTextColor(0, 0, 0); // Set text color back to black
    doc.setFont("helvetica", "bold"); // Set font to bold
    doc.text(
      "Previous Institute Details from where last examination has been passed",
      5,
      165
    );

    doc.autoTable({
      body: previnstName,

      startY: 167,
      margin: { left: 5 }, // Set the desired x-position here,

      columnStyles: {
        0: { fillColor: "#fff", fontSize: 10, cellWith: 35 },
        1: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        2: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 120,
          fontStyle: "bold",
        },
         3: { fillColor: "#fff", fontSize: 10, cellWith: 35 },
        4: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        5: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 50,
          fontStyle: "bold",
        },
             },
    });
    doc.autoTable({
      body: previnstData,

      startY: 173,
      margin: { left: 5 }, // Set the desired x-position here,

      columnStyles: {
        0: { fillColor: "#fff", fontSize: 10, cellWith: 35 },
        1: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        2: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 100,
          fontStyle: "bold",
        },
        3: { fillColor: "#fff", fontSize: 10, cellWith: 15 },
        4: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        5: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 35,
          fontStyle: "bold",
        },
      },
    });

    // Add a black background box with white text
    doc.setFillColor(0, 0, 0); // Black color for background
    doc.rect(3, 185, 115, 8, "F"); // Draw a filled rectangle

    // Add the text inside the box
    doc.setTextColor(255, 255, 255); // White color for text
    doc.setFontSize(10);
    doc.text(
      " Section (B) : Details of Current Institute (where student is studying)",
      60,
      190,
      {
        align: "center",
      }
    );
    doc.autoTable({
      body: currentinstName,

      startY: 195,
      margin: { left: 5 }, // Set the desired x-position here,

      columnStyles: {
        0: { fillColor: "#fff", fontSize: 10, cellWith: 50 },
        1: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        2: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 120,
          fontStyle: "bold",
        },
       
      },
    });

    doc.autoTable({
      body: currentinstData1,

      startY: 222,
      margin: { left: 5 }, // Set the desired x-position here,

      columnStyles: {
        0: { fillColor: "#fff", fontSize: 10, cellWith: 70 },
        1: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        2: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 40,
          fontStyle: "bold",
        },
        3: { fillColor: "#fff", fontSize: 10, cellWith: 40 },
        4: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        5: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 40,
          fontStyle: "bold",
        },
      },
    });
doc.autoTable({
      body: currentinstData,

      startY: 244,
      margin: { left: 5 }, // Set the desired x-position here,

      columnStyles: {
        0: { fillColor: "#fff", fontSize: 10, cellWith: 50 },
        1: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        2: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 120,
          fontStyle: "bold",
        },
              },
    });

    doc.autoTable({
      body: currentinstData2,

      startY: 251,
      margin: { left: 5 }, // Set the desired x-position here,

      columnStyles: {
        0: { fillColor: "#fff", fontSize: 10, cellWith: 58 },
        1: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        2: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 30,
          fontStyle: "bold",
        },
        3: { fillColor: "#fff", fontSize: 10, cellWith: 13 },
        4: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        5: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 20,
          fontStyle: "bold",
        },
        6: { fillColor: "#fff", fontSize: 10, cellWith: 27 },
        7: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        8: { fillColor: "#fff", fontSize: 11, cellWith: 30, fontStyle: "bold",
        },
      },
    });
    doc.autoTable({
      body: currentinstData3,

      startY: 257,
      margin: { left: 5 }, // Set the desired x-position here,

      columnStyles: {
        0: { fillColor: "#fff", fontSize: 10, cellWith: 50 },
        1: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        2: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 30,
          fontStyle: "bold",
        },
        3: { fillColor: "#fff", fontSize: 10, cellWith: 80 },
        4: { fillColor: "#fff", fontSize: 10, cellWith: 5 },
        5: {
          fillColor: "#fff",
          fontSize: 11,
          cellWith: 30,
          fontStyle: "bold",
        },
        
      },
    });

 // Add a black background box with white text
 doc.setFillColor(0, 0, 0); // Black color for background
 doc.rect(3, 268, 115, 8, "F"); // Draw a filled rectangle

 // Add the text inside the box
 doc.setTextColor(255, 255, 255); // White color for text
 doc.setFontSize(10);
 doc.text(
   " Section (C) : Attestation from the Head of Department OR Institute.",
   60,
   273,
   {
     align: "center",
   }
 );

 doc.setTextColor(0, 0, 0); // Black color for text
doc.setFont("helvetica", "normal"); // Set font back to normal
doc.setFontSize(12);

// const x = 7; // X position for the text
// const y = 282; // Y position for the text
// const maxWidth = 200; // Set the desired maximum width here
// const lineHeight = 10; // Set the desired line height here

// const text =
//   "It is hereby certified that, Mr. / Ms. _____________________________ is a regular student of this institution and has enrolled in the _______________________ degree program. The total duration of his/her current degree/course is _________ years __________ months, and he/she has shown commendable performance so far.";

// // Split the text into an array of lines based on the maxWidth
// const lines = doc.splitTextToSize(text, maxWidth);


// // Calculate the total height of the wrapped text
// // const textHeight = lines.length * lineHeight;

// // Render the wrapped text within the specified width
// doc.text(lines, x, y, {
//   align: "justify",
//   maxWidth: maxWidth,
//   lineHeight: lineHeight,
// });

doc.setTextColor(0, 0, 0); // Black color for text
doc.setFont("helvetica", "normal"); // Set font back to normal
doc.setFontSize(12);

const x = 6; // X position for the text
const y = 282; // Y position for the text
const maxWidth = 205; // Set the desired maximum width here
const lineHeight = 6.5; // Set the desired line height with gap here

const text =
  "It is hereby certified that, Mr. / Ms. _____________________________ is a regular student of this institution and has enrolled in the _______________________ degree program. The total duration of his/her current degree/course is _________ years __________ months, and he/she has shown commendable performance so far.";

// Split the text into an array of lines based on the maxWidth
const lines = doc.splitTextToSize(text, maxWidth);

// Loop through each line and add it to the PDF
lines.forEach((line, index) => {
  doc.text(x, y + index * lineHeight, line, 
    {
        align: "justify",
        maxWidth: maxWidth,
        // lineHeight: lineHeight,
      });
});


// // Optionally, you can draw a rectangle around the wrapped text
// doc.setDrawColor(0); // Black color for the rectangle border
// doc.rect(x, y, maxWidth, textHeight, "S"); // Draw the rectangle

doc.setTextColor(0, 0, 0); // Black color for text
doc.setFont("helvetica", "normal"); // Set font back to normal
doc.setFontSize(11);

// const x = 10; // X position for the text
// const y = 295; // Y position for the text
// const maxWidth = 200; // Set the desired maximum width here
// const lineHeight = 14; // Set the desired line height here

const text2 =
  "Name and Designation of Attesting Officer_________________________, Sign and Stamp____________________";

// Split the text into an array of lines based on the maxWidth
const lines2 = doc.splitTextToSize(text2, maxWidth);

// Calculate the total height of the wrapped text
// const textHeight = lines.length * lineHeight;

// Render the wrapped text within the specified width
doc.text(lines2, 5, 308, {
  align: "justify",
  maxWidth: maxWidth,
  lineHeight: lineHeight,
});

// // Optionally, you can draw a rectangle around the wrapped text
// doc.setDrawColor(0); // Black color for the rectangle border
// doc.rect(x, y, maxWidth, textHeight, "S"); // Draw the rectangle

// Add a black background box with white text
doc.setFillColor(0, 0, 0); // Black color for background
doc.rect(3, 313, 138.5, 8, "F"); // Draw a filled rectangle

// Add the text inside the box
doc.setTextColor(255, 255, 255); // White color for text
doc.setFontSize(10);
doc.setFont("helvetica", "bold"); // Set font back to normal
doc.text(
  " Section (D) : Details of the hostel provided by the current educational institution.",
  71,
  318,
  {
    align: "center",
  }
);

doc.setTextColor(0, 0, 0); // Set text color back to black
doc.setFont("helvetica", "normal"); // Set font to bold
doc.setFontSize(10);
    doc.text("(Mark the relevant field).If YES,\n then attestation is required", 125, 328);
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal"); // Set font back to normal
    // const xStart = 20; // X position to start rendering checkboxes
    // const yStart = 35; // Y position to start rendering checkboxes
    // const checkboxGap = 28; // Gap between checkboxes
    
    doc.setFont("helvetica", "normal"); // Set font to bold
    doc.text(" Are you staying in the hostel of the institute?", 5, 328);
    doc.setFont("helvetica", "normal"); // Set font to bold
    // Render the province checkboxes in a row
    Hostel.forEach((hostel, index) => {
      const xPosition = 90 + index * checkboxGap;
      const yPosition = 326 + 2; // Increase the yPosition to avoid overlapping with the province label
      doc.text(hostel.label, xPosition, yPosition);

      // Check if the current province matches the selected province
      const isChecked = formData.isStayingInHostel === hostel.name;
      console.log("formData.Hostel:", formData.isStayingInHostel);
      console.log("formData.isStayingInHostel", hostel.name);
      // console.log("province.name:", province.name);
      console.log("isChecked:", isChecked);

      // Render the checkboxes in the PDF
      doc.setDrawColor(0); // Black color for checkbox border
      doc.rect(xPosition - 6, yPosition - 3, 5, 5, "S"); // Draw the checkbox border
      // if (isChecked) {
      //   doc.setFillColor(0); // Fill the checkbox with black color if checked
      //   doc.rect(xPosition - 4, yPosition - 2, 5, 5, "FD"); // Draw the filled checkbox
      // }

 // If the checkbox is checked, place the tick image inside the checkbox
 if (isChecked) {
  doc.addImage(checkboxImage, xPosition - 6, yPosition - 3, 5, 5);
}
   });

   doc.setTextColor(0, 0, 0); // Black color for text
   doc.setFont("helvetica", "normal"); // Set font back to normal
   doc.setFontSize(10);
   
  //  const x = 10; // X position for the text
  //  const y = 295; // Y position for the text
  //  const maxWidth = 200; // Set the desired maximum width here
  //  const lineHeight = 14; // Set the desired line height here
   
   const text3 =
     "Certified that, Mr./Ms. ____________________ is a regular student of this institute and that he/she is residing in the hostel.";
   
   // Split the text into an array of lines based on the maxWidth
   const lines3 = doc.splitTextToSize(text3, maxWidth);
   
   // Calculate the total height of the wrapped text
   // const textHeight = lines.length * lineHeight;
   
   // Render the wrapped text within the specified width
   doc.text(lines3, 5, 337, {
     align: "justify",
     maxWidth: maxWidth,
     lineHeight: lineHeight,
   });
   
   // // Optionally, you can draw a rectangle around the wrapped text
   // doc.setDrawColor(0); // Black color for the rectangle border
   // doc.rect(x, y, maxWidth, textHeight, "S"); // Draw the rectangle
   
   doc.setTextColor(0, 0, 0); // Black color for text
   doc.setFont("helvetica", "normal"); // Set font back to normal
   doc.setFontSize(10);
   
   // const x = 10; // X position for the text
   // const y = 295; // Y position for the text
   // const maxWidth = 200; // Set the desired maximum width here
   // const lineHeight = 14; // Set the desired line height here
   
   const text4 =
     " Hostel Warden/ Superintendent Name________________, Contact No._______________, Sign & Stamp_______________";
   
   // Split the text into an array of lines based on the maxWidth
   const lines4 = doc.splitTextToSize(text4, maxWidth);
   
   // Calculate the total height of the wrapped text
   // const textHeight = lines.length * lineHeight;
   
   // Render the wrapped text within the specified width
   doc.text(lines4, 5, 345, {
     align: "justify",
     maxWidth: maxWidth,
     lineHeight: lineHeight,
   });
// Add a black background box with white text
doc.setFillColor(0, 0, 0); // Black color for background
doc.rect(3, 348, 209, 6, "F"); // Draw a filled rectangle

// Add the text inside the box
doc.setTextColor(255, 255, 255); // White color for text
doc.setFontSize(7);
doc.setFont("helvetica", "bold"); // Set font back to normal
doc.text(
  " Note: PEEF award resident scholarships only to students staying in the same institute's hostel.Student residing in private hostels or in other institutes are not eligible for this.",
  107,
  352,
  {
    align: "center",
  }
);   

doc.addPage();

// Add a black background box with white text
doc.setFillColor(0, 0, 0); // Black color for background
doc.rect(3, 5, 88, 8, "F"); // Draw a filled rectangle

// Add the text inside the box
doc.setTextColor(255, 255, 255); // White color for text
doc.setFontSize(10);
doc.setFont("helvetica", "bold"); // Set font back to normal
doc.text(
  " Section (E) : Details of Other availing Scholarship.",
  46,
  10,
  {
    align: "center",
  }
);

doc.setTextColor(0, 0, 0); // Set text color back to black
doc.setFont("helvetica", "normal"); // Set font to bold
doc.setFontSize(10);
    doc.text("(Mark the relevant field).", 145, 18 );
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal"); // Set font back to normal
    // const xStart = 20; // X position to start rendering checkboxes
    // const yStart = 35; // Y position to start rendering checkboxes
    // const checkboxGap = 28; // Gap between checkboxes
    
    doc.setFont("helvetica", "normal"); // Set font to bold
    doc.text(" Are you availing Scholarship in present Academic year?", 5, 18);
    doc.setFont("helvetica", "normal"); // Set font to bold
    // Render the province checkboxes in a row
    Scholarship.forEach((scholarship, index) => {
      const xPosition = 110 + index * checkboxGap;
      const yPosition = 16 + 2; // Increase the yPosition to avoid overlapping with the province label
      doc.text(scholarship.label, xPosition, yPosition);

      // Check if the current province matches the selected province
      const isChecked = formData.isReceivingScholarship === scholarship.name;
      console.log("formData.Hostel:", formData.isReceivingScholarship);
      console.log("formData.isReceivingScholarship", scholarship.name);
      // console.log("province.name:", province.name);
      console.log("isChecked:", isChecked);

      // Render the checkboxes in the PDF
      doc.setDrawColor(0); // Black color for checkbox border
      doc.rect(xPosition - 6, yPosition - 3, 5, 5, "S"); // Draw the checkbox border
      // if (isChecked) {
      //   doc.setFillColor(0); // Fill the checkbox with black color if checked
      //   doc.rect(xPosition - 4, yPosition - 2, 5, 5, "FD"); // Draw the filled checkbox
      // }

 // If the checkbox is checked, place the tick image inside the checkbox
 if (isChecked) {
  doc.addImage(checkboxImage, xPosition - 6, yPosition - 3, 5, 5);
}
   });


doc.setTextColor(0, 0, 0); // Black color for text
   doc.setFont("helvetica", "normal"); // Set font back to normal
   doc.setFontSize(11);
   
  //  const x = 10; // X position for the text
  //  const y = 295; // Y position for the text
  //  const maxWidth = 200; // Set the desired maximum width here
  //  const lineHeight = 14; // Set the desired line height here
   
   const text5 =
     "Note: As per Policy of PEEF, student is not allowed to avail more than ONE scholarship. If scholarship is awarded, student have to withdraw from the already availing Scholarship.";
   
   // Split the text into an array of lines based on the maxWidth
   const lines5 = doc.splitTextToSize(text5, maxWidth);
   
   // Calculate the total height of the wrapped text
   // const textHeight = lines.length * lineHeight;
   
   // Render the wrapped text within the specified width
   doc.text(lines5, 5, 25, {
     align: "justify",
     maxWidth: maxWidth,
     lineHeight: lineHeight,
   });

   // Add a black background box with white text
doc.setFillColor(0, 0, 0); // Black color for background
doc.rect(3, 32, 70, 8, "F"); // Draw a filled rectangle

// Add the text inside the box
doc.setTextColor(255, 255, 255); // White color for text
doc.setFontSize(10);
doc.setFont("helvetica", "bold"); // Set font back to normal
doc.text(
  " Section (F) : Special Quota Scholarship.",
  37,
  37,
  {
    align: "center",
  }
);






doc.setTextColor(0, 0, 0); // Black color for text
   doc.setFont("helvetica", "normal"); // Set font back to normal
   doc.setFontSize(11);
   

   
   const text6 =
     "If you fall in any of the following category, then you are also eligible for Special Quota Scholarship. for this purpose mention the detail of relevant category and also attach the photocopy of documents / certificates";
   
   // Split the text into an array of lines based on the maxWidth
   const lines6 = doc.splitTextToSize(text6, maxWidth);
   
   // Calculate the total height of the wrapped text
   // const textHeight = lines.length * lineHeight;
   
   // Render the wrapped text within the specified width
   doc.text(lines6, 5, 45, {
     align: "justify",
     maxWidth: maxWidth,
     lineHeight: lineHeight,
   });
   doc.autoTable({
    head: [["S.No", "Special Quota Category", "Mark the relevant field", "Documents / Certificate required with Application"]],
    body: specialQuotaData,

    startY: 55,
    margin: { left: 5 }, // Set the desired x-position here,
    styles:{lineWidth: 0.1, // Set the desired border width here
   },
   headStyles: {
    fillColor: "#ffffff", // White color for the header background
    textColor: "#000000", // Black color for the header text
    fontSize: 6, // Set the desired font size for the header
   },
    columnStyles: {
      0: { fillColor: "#fff", fontSize: 10, cellWith: 10 },
      1: { fillColor: "#fff", fontSize: 10, cellWith: 40 },
      2: { fillColor: "#fff", fontSize: 10, cellWith: 20 },
      3: { fillColor: "#fff", fontSize: 10, cellWith: 130 },
           
    },
  });

  

  doc.setTextColor(0, 0, 0); // Black color for text
  doc.setFont("helvetica", "normal"); // Set font back to normal
  doc.setFontSize(12);
  
  // const x = 10; // X position for the text
  // const y = 295; // Y position for the text
  // const maxWidth = 200; // Set the desired maximum width here
  // const lineHeight = 14; // Set the desired line height here
  
  console.log(formData.SpecialQuota); // Log specialQuota to the console

const xStart2 = 67;
const yStart2 = 67.75;
const checkboxGap2 = 13;

const tableRows = specialQuota.map((quota, index) => {
  const xPosition = xStart2;
  const yPosition = yStart2 + index * checkboxGap2 + 1.5;
  const isChecked = formData.selectedSpecialQuota === quota.name;

  return {
    label: quota.label,
    isChecked: isChecked,
    xPosition: xPosition,
    yPosition: yPosition,
  };
});

doc.setFontSize(12);

tableRows.forEach((row) => {
  doc.text(row.label, row.xPosition, row.yPosition);

  doc.setDrawColor(0); // Black color for checkbox border
  // doc.rect(row.xPosition - 6, row.yPosition - 2, 6, 6, "S"); // Draw the checkbox border

  if (row.isChecked) {
    doc.addImage(checkboxImage, row.xPosition - 8, row.yPosition + 2, 6, 6); // Place the tick image inside the checkbox
  }
});




  
  const text12 =
    "Note: The mentioned student belongs to___________________ category.                                                                                                                                                                                                                       Verification(Head of Institute) ________________,    Signature & Stamp_______________";
  
  // Split the text into an array of lines based on the maxWidth
  const lines12 = doc.splitTextToSize(text12, maxWidth);
  
  // Calculate the total height of the wrapped text
  // const textHeight = lines.length * lineHeight;
  
  // Render the wrapped text within the specified width
  doc.text(lines12, 5, 140, {
    align: "justify",
    maxWidth: maxWidth,
    lineHeight: lineHeight,
  });


// Add the text inside the box
doc.setTextColor(0, 0, 0); // White color for text
doc.setFontSize(12);
doc.setFont("helvetica", "bold"); // Set font back to normal
doc.text(
  " Undertaking from Father/Mother and Student",
  5,
  160,
  {
    align: "left",
  }
);


doc.setTextColor(0, 0, 0); // Black color for text
   doc.setFont("helvetica", "normal"); // Set font back to normal
   doc.setFontSize(12);
   
  const text7 =
     "It is hereby solemnly affirm that all the information and qualifications provided above are true and accurate to the best of my knowledge. I assure that no relevant details have been withheld. It is further affirmed that the total combined income of my parents does not exceed Rs. 60,000 /-. Furthermore Income Affidavit has been attached fro verification purpose.";
   
   // Split the text into an array of lines based on the maxWidth
   const lines7 = doc.splitTextToSize(text7, maxWidth);
   
  //  Calculate the total height of the wrapped text
  //  const textHeight = lines.length * lineHeight;
   
   // Render the wrapped text within the specified width
   doc.text(lines7, 5, 168, {
     align: "justify",
     maxWidth: maxWidth,
     lineHeight: lineHeight,
   });

   doc.setTextColor(0, 0, 0); // Black color for text
   doc.setFont("helvetica", "normal"); // Set font back to normal
   doc.setFontSize(10);
   
   // const x = 10; // X position for the text
   // const y = 295; // Y position for the text
   // const maxWidth = 200; // Set the desired maximum width here
   // const lineHeight = 14; // Set the desired line height here
   
   const text8 =
     " Student Signature________________,             Father/Mother Signature_______________";
   
   // Split the text into an array of lines based on the maxWidth
   const lines8 = doc.splitTextToSize(text8, maxWidth);
   
   // Calculate the total height of the wrapped text
   // const textHeight = lines.length * lineHeight;
   
   // Render the wrapped text within the specified width
   doc.text(lines8, 65, 190, {
     align: "justify",
     maxWidth: maxWidth,
     lineHeight: lineHeight,
   });

   doc.setTextColor(0, 0, 0); // White color for text
   doc.setFontSize(11);
   doc.setFont("helvetica", "bold"); // Set font back to normal
   doc.text(
     " Most Important: Incase of Incomplete / Wrong Information, this institute holds the right to reject the application. ",
     5,
     198,
     {
       align: "left",
     }
   );

   // Add the text inside the box
doc.setTextColor(0, 0, 0); // White color for text
doc.setFontSize(12);
doc.setFont("helvetica", "bold"); // Set font back to normal
doc.text(
  " Eligibility Criteria",
  5,
  205,
  {
    align: "left",
  }
);

doc.setTextColor(0, 0, 0); // White color for text
   doc.setFontSize(9);
   doc.setFont("helvetica", "normal"); // Set font back to normal
   doc.text(
     " 1. Marks Obtained in Matric/Intermediate shall not be less than 60%. ",
     5,
     210,
     {
       align: "left",
     }
   );
   doc.text(
    " 2. After Passing Exam Student has taken admission in any institute in the same year. ",
    5,
    217,
    {
      align: "left",
    }
  );
  doc.text(
    " 3. Total Income of Parents is not more than Rs. 60,000/- ",
    128,
    217,
    {
      align: "left",
    }
  );

  doc.text(
    " 4. Children of Govt. Servant (Grade 1-4) are exempted from the condition of a monthly income of 60,000 rupees (if the only source of income\n    is the salary).      5.Children of the civilians who were martyred in terrorist attacks are exempted from month income condition.  ",
    5,
    224,
    {
      align: "left",
    }
  );

   // Add a black background box with white text
   doc.setFillColor(0, 0, 0); // Black color for background
   doc.rect(3, 233, 200, 8, "F"); // Draw a filled rectangle
   
   // Add the text inside the box
   doc.setTextColor(255, 255, 255); // White color for text
   doc.setFontSize(10);
   doc.setFont("helvetica", "bold"); // Set font back to normal
   doc.text(
     " Kindly Check that following documents and certificate has been attached. Tick mark in front of the attached document",
     102,
     238,
     {
       align: "center",
     }
   );






   doc.autoTable({
    body: document,

    startY: 245,
    margin: { left: 5 }, // Set the desired x-position here,
    styles:{lineWidth: 0.1, // Set the desired border width here
   },
    columnStyles: {
      0: { fillColor: "#fff", fontSize: 10, cellWith: 190 },
      1: { fillColor: "#fff", fontSize: 10, cellWith: 10 },
           
    },
  });





   doc.setTextColor(0, 0, 0); // White color for text
   doc.setFontSize(12);
   doc.setFont("helvetica", "normal"); // Set font back to normal
   doc.text(
     " Note: Submit the Hard Copy along with relevant documents at following address:",
     5,
     302,
     {
       align: "left",
     }
   );

   doc.setTextColor(0, 0, 0); // White color for text
   doc.setFontSize(12);
   doc.setFont("helvetica", "bold"); // Set font back to normal
   doc.text(
     "Office of The Additional Secretary (Endowment) / Focal Person PEEF, \nOld KDa Building No. 3, Third Floor, Sindh Secretariate, Karachi",
     110,
     310,
     {
       align: "center",
     }
   );
  

   doc.setTextColor(0, 0, 0); // Black color for text
   doc.setFontSize(12);
   
   //  const x = 10; // X position for the text
   //  const y = 295; // Y position for the text
   //  const maxWidth = 200; // Set the desired maximum width here
   //  const lineHeight = 14; // Set the desired line height here
   
   doc.setFont("helvetica", "Bold"); // Set font back to normal
   const text9 =
   "For Office Use Only:";
   
   // Split the text into an array of lines based on the maxWidth
   const lines9 = doc.splitTextToSize(text9, maxWidth);
   
   // Calculate the total height of the wrapped text
   //  const textHeight = lines.length * lineHeight;
   
   // Render the wrapped text within the specified width
   doc.text(lines9, 30, 325, {
     align: "center",
     maxWidth: maxWidth,
     lineHeight: lineHeight,
    });
    
    doc.setFont("helvetica", "normal"); // Set font back to normal
   const text10 =
   "Name of Focal Person ___________________________, Designation_______________________";
 
 // Split the text into an array of lines based on the maxWidth
 const lines10 = doc.splitTextToSize(text10, maxWidth);
 
 // Calculate the total height of the wrapped text
//  const textHeight = lines.length * lineHeight;
 
 // Render the wrapped text within the specified width
 doc.text(lines10, 15, 331, {
   align: "justify",
   maxWidth: maxWidth,
   lineHeight: lineHeight,
 });
 
//  doc.setFont("helvetica", "normal"); // Set font back to normal
 const text11 =
 "Sign & Stamp _____________________________, Date___________________________";

// Split the text into an array of lines based on the maxWidth
const lines11 = doc.splitTextToSize(text11, maxWidth);

// Calculate the total height of the wrapped text
// const textHeight = lines.length * lineHeight;

// Render the wrapped text within the specified width
doc.text(lines11, 15, 345, {
 align: "justify",
 maxWidth: maxWidth,
 lineHeight: lineHeight,
});
 
 // // Optionally, you can draw a rectangle around the wrapped text
   doc.setDrawColor(0); // Black color for the rectangle border
   doc.rect(5, 320, 200, 30, "S"); // Draw the rectangle


    // Save the PDF
    doc.save("PEEF Scholarship Form.pdf");
  };

  return (
    <div>
      
      <Button
      variant="contained" 
      color="primary"
      onClick={handleDownloadPDF}>Download Scholarship Form</Button>
    </div>
  );
}
