import React from "react";
import Forward from "../components/ads/Forward";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import axios from "axios";
import Header from "../components/Header/Header"
// import Pdf from '../form/Pdf'
import { saveAs } from "file-saver";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(3.5),
    background: theme.palette.background.default,
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(0.2), // Decrease padding from top for small screens
    },
  },
  container: {
    maxWidth: 600,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.grey[300],
    boxShadow: theme.shadows[7],
    marginTop: theme.spacing(5), // Add margin top here
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(5),
    textAlign: "center",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.9rem", // Decrease font size for smaller screens
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem", // Decrease font size for smaller screens
      fontWeight: "bold",
    },
  },
  button: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.73rem", // Decrease font size for small screens
      padding: theme.spacing(1, 4), // Decrease button padding for small screens
    },
  },

  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0), // Decrease margin top for small screens
    },
  },
  logo: {
    width: 80,
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1), // Added margin right to create a gap
    },
  },
  department: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(10),
      "& > *": {
        fontSize: "1.2rem", // Increase the font size for LG screens
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },

  contact: {
    marginTop: theme.spacing(0),
    fontSize: "0.9125rem", // Increase the font size
    textAlign: "justified",
    fontWeight: "bold", // Make the text bold
  },
}));

function Landing() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleApply = () => {
    navigate("/form");
  };
  const handlePdf = () => {
    // navigate("/pdf");
     // Specify the filename of the PDF you want to download
  const fileName = "IncomeAffidavitInterGrad2022.pdf"; // Replace with the actual filename of your PDF file
  const filePath = "/IncomeAffidavitInterGrad2022.pdf";

  // Trigger the download using FileSaver.js
  saveAs(filePath, fileName);
};

  

  const handleLogin = () => {
    navigate("/login");
  };

  // const handleClick = () => {
  //   // Define the functionality you want to use here
  //   console.log("Button Clicked!");
  // };



  // const handleCheckCnic = () => {
  //   Swal.fire({
  //     title: "Check CNIC and Mobile Number",
  //     html: `
  //       <input type="text" id="cnic" class="swal2-input" placeholder="CNIC (e.g. 12345-6789123-4)">
  //       <input type="text" id="mobile" class="swal2-input" placeholder="Mobile Number (e.g. 0300-1234567)">
  //     `,
  //     showCancelButton: true,
  //     confirmButtonText: "Verify",
  //     cancelButtonText: "Cancel",
  //     preConfirm: () => {
  //       const cnic = document.getElementById("cnic").value;
  //       const mobile = document.getElementById("mobile").value;
  //       const cnicPattern = /^\d{5}-\d{7}-\d$/;
  //       const mobilePattern = /^\d{4}-\d{7}$/;
  
  //       if (!cnicPattern.test(cnic) || !mobilePattern.test(mobile)) {
  //         Swal.showValidationMessage("Invalid CNIC or Mobile Number format");
  //         return;
  //       }
  
  //       return { cnic, mobile };
  //     },
  //     allowOutsideClick: () => !Swal.isLoading(),
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // Handle the verified CNIC and Mobile Number here
  //       const { cnic, mobile } = result.value;
  //       axios
  //         .post("http://localhost:8000/api/check-cnic-and-mobile", { cnic, mobile })
  //         .then((res) => {
  //           if (res.data.data) {
  //             // CNIC and Mobile Number match, and data is fetched
  //             const fetchedData = res.data.data;
  //             console.log("Fetched Data:", fetchedData);
  //             Swal.fire({
  //               title: "CNIC and Mobile Number Verified",
  //               text: `The entered CNIC and Mobile Number combination exists in the database with ID: ${fetchedData.id}`,
  //               icon: "success",
  //               confirmButtonText: "OK",
  //             });
  //           } else {
  //             // CNIC and Mobile Number do not match
  //             Swal.fire({
  //               title: "CNIC and Mobile Number Not Found",
  //               text: "The entered CNIC and Mobile Number combination does not exist in the database",
  //               icon: "error",
  //               confirmButtonText: "OK",
  //             });
  //           }
  //         })
  //         .catch((err) => {
  //           console.log("An error occurred while checking the CNIC and Mobile Number:", err);
  //         });
  //     }
  //   });
  // };
  
  
  
  



  const handleTutorial = () => {
    // Specify the URL of the video you want to open
    const videoUrl = "https://youtu.be/tq1bFxxl35Y";
  
    // Specify the window features for the popup window
    const windowFeatures = "width=800,height=600,top=100,left=100,resizable=yes";
  
    // Open the video URL in a popup window
    window.open(videoUrl, "_blank", windowFeatures);
  };


  
  return (
    <div className={classes.root}>
      <header className={classes.header}>
<Header />
        {/* <div className={classes.logo}> */}
          {/* <img src="/logo.png" alt="Logo" style={{ width: "100%" }} /> */}
        {/* </div> */}
        {/* <div className={classes.department}> */}
          {/* <Typography variant="h6" component="h2" style={{ fontWeight: "bold" }}>
            GOVERNMENT OF SINDH
          </Typography>
          <Typography variant="subtitle1" component="p">
            COLLEGE EDUCATION DEPARTMENT
          </Typography>
          <Typography variant="subtitle2" component="p">
            SINDH EDUCATIONAL ENDOWMENT FUND
          </Typography>
          <Typography variant="subtitle2" component="p">
            REGISTERED VIDE TRUST DEED NO. 625/2020
          </Typography> */}
        {/* </div> */}
      </header>
        <Forward dataAdSlot= 'f08c47fec0942fa0' />
      <Container className={classes.container}>
        <Typography variant="h4" component="h1" className={classes.title}>
          Welcome To <br /> The Punjab Educational Endowment Fund <br /> 
          <br /> (For Intermediate, ADA, ADC & ADS program students only)
        </Typography>
        <form>
          
        {/* <Button
  variant="contained"
  color="primary"
  size="medium"
  className={classes.button}
  fullWidth
  onClick={handleTutorial}
>
  How to Fill SEEF Scholarship Form
</Button> */}


          
          {/* <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            fullWidth
            onClick={handleApply}
          >
            Apply Online for Scholarship
          </Button> */}

          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            fullWidth
            onClick={handlePdf}
          >
Download Income Affidavit Sample
          </Button>


          

          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            fullWidth
            onClick={handleLogin}
          >
            Login
          </Button>
          

       </form>






        <Typography variant="h6" component="h2" className="underline ">
          Contact Us:
        </Typography>
        <Typography variant="body2" component="p" className={classes.contact}>
          For any queries, please contact <strong className="underline text-blue-800">Mr. Usman Ali Mangrio</strong> at <strong className="underline text-blue-800">0345-2132045</strong>
        </Typography>
      </Container>
    </div>
  );
}

export default Landing;
